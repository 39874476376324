import React from "react";
import QueueAnim from 'rc-queue-anim';
import './index.less'
import { Col, Row } from "antd";
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';
export default class LidarBanner extends React.Component {
    componentDidMount() {
      

    }

    render() {
        return (
            <div className='Lidar-banner'>
                <div className="Lidar-banner-content">
                    <h1><Texty>Full Scan Without Any Trace</Texty></h1>
                    <div className="video-container video">
                    <video 
                     x5-playsinline="true"
                     playsinline="true" 
                     webkit-playsinline="true"
                    src="http://qiniu.metridynamic.com/video/new%20video/payload/lidar.mp4" autoPlay loop muted>

                    </video>
                </div>
                </div>
            </div>
        )

    }
}
