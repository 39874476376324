import React from 'react';
import './style.less';
import { Row, Col } from 'antd';
import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';
import { OverPack } from 'rc-scroll-anim';
class MobileAircraftInfo extends React.PureComponent {
    componentDidMount() {

    }

    render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    const arr = [1,2,3,4,5,6];
    delete props.dataSource;
    delete props.isMobile;
      const style = { background: '#0092ff', padding: '8px 0' };
      const colStyle = {marginBottom:48}
      return (
      <div className='mobile-aircraft-info-wrapper'>


              <div className="mobile-description">
                  <video src="http://qiniu.metridynamic.com/video/new%20video/payload/20220411-143637.mp4" autoPlay muted loop>

                  </video>
              </div>
                  <div className="description-container">
                      {/* <div className="mobile-description">
                          <h3>Metridynamic P series</h3>
                      </div> */}

                      <div className="mobile-description">
                          <h3>Make Wings for Your Imagination </h3>
                      </div>

                      <div className="mobile-description">
                      <div className='p'>As a pioneer of latest aerodynamic technology and cutting-edged industrial design, MetriDynamic has always been dedicated to manufacturing qualitied and versatile evtols with great capacity and capability, which has made it the first choice for many individual and industrial customers.  </div>
                      </div>

                      <div className="mobile-description">
                         <div className='line'>
                             <Row>
                                 <Col style={colStyle} xs={12} sm={12} md={8} lg={8} xl={8}>
                                     <img src="http://qiniu.metridynamic.com/image%2Fp4%2FPayload%20interchangable.png" alt={""}/>
                                     <p>Payload interchangeable</p>
                                 </Col>
                                 <Col style={colStyle} xs={12} sm={12} md={8} lg={8} xl={8}>
                                     <img src="http://qiniu.metridynamic.com/image%2Fp4%2FAll%20electric.png" alt={""}/>
                                     <p>All electric</p>
                                 </Col>
                                 <Col style={colStyle} xs={12} sm={12} md={8} lg={8} xl={8}>
                                     <img src="http://qiniu.metridynamic.com/image/new-official-en/P4icon/level.png" alt={""}/>
                                     <p>Level 6 Wind <br/>Resistance</p>
                                 </Col>
                                 <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                     <img src="http://qiniu.metridynamic.com/image/new-official-en/P4icon/distance.png" alt={""}/>
                                     <p>100km Max Control distance</p>
                                 </Col>
                                 <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                     <img src="http://qiniu.metridynamic.com/image/new-official-en/P4icon/max.png" alt={""}/>
                                     <p>6kg Max Payload</p>
                                 </Col>
                                 <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                                     <img src="http://qiniu.metridynamic.com/image/new-official-en/P4icon/weight.png" alt={""}/>
                                     <p>15kg-class MTOW</p>
                                 </Col>
                             </Row>
                         </div>
                      </div>
                  </div>
      </div>
    );
  }
}

export default MobileAircraftInfo;
