import React from "react";
import './index.less';
import Content5 from "../../components/content5/Content5";
import CraftInfo from "./components/craftInfo/craftInfo";
import FlyingVideo from "./components/video";
import MobileContent from "./components/mobile";
import PayloadBanner from "./components/payloadBanner/payloadBanner";
import GroundBanner from "./components/groundBanner/groundBanner";
import {Content50DataSource} from '../../data.source';
import {enquireScreen} from 'enquire-js';

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});
export default class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
        };
    }

    render() {
        console.log(isMobile, "isMobile")
        return (
            <div className={'home templates-wrapper'}>
                <div className='flying-video'>
                    <FlyingVideo/>
                </div>
                {/*{*/}
                {/*    this.state.isMobile*/}
                {/*        ?*/}
                {/*        <P6homeMobile />*/}
                {/*        :*/}
                {/*        <P6home />*/}
                {/*}*/}
                <CraftInfo/>
                <PayloadBanner/>
                <GroundBanner/>
                {/*<Page1 key="page1" isMobile={this.state.isMobile} />*/}
                {/*<Page2 key="page2" />*/}
                {/*<Page3 key="page3" isMobile={this.state.isMobile} />*/}
                {/*<Page4 key="page2" />*/}
                {
                    this.state.isMobile
                        ?
                        <MobileContent
                            dataSource={Content50DataSource}
                            isMobile={this.state.isMobile}
                        />
                        :
                        <Content5
                            id="Content5_0"
                            key="Content5_0"
                            dataSource={Content50DataSource}
                            isMobile={this.state.isMobile}
                        />
                }
                
            </div>
        )
    }
}
