import React from "react";
import QueueAnim from 'rc-queue-anim';
import './style.less'
import {Card, Row, Col} from 'antd';

const {Meta} = Card;

export default class ParametersContent extends React.Component {

    render() {
        return (
            <div className="gimbal-description-container">
                <div className="description">
                    <div className='line'>
                        <Row justify="center">
                            <Col xs={8} sm={12} md={8} lg={8} xl={8}>
                                <img src="http://qiniu.metridynamic.com/image/new-official-en/oblique/%E5%85%8D%E7%9B%B8%E6%8E%A7.png" alt={""}/>
                                <p>5 lens in 1</p>
                            </Col>
                            <Col xs={8} sm={12} md={8} lg={8} xl={8}>
                                <img src="http://qiniu.metridynamic.com/image/new-official-en/oblique/%E8%88%AA%E7%89%87%E5%89%94%E9%99%A4.png" alt={""}/>
                                <p>Photo culling</p>
                            </Col>
                            <Col xs={8} sm={12} md={8} lg={8} xl={8}>
                                <img src="http://qiniu.metridynamic.com/image/new-official-en/oblique/APS-C%E7%94%BB%E5%B9%85.png" alt={""}/>
                                <p>Fast storage</p>
                            </Col>
                        </Row>
                    </div>
                    <div className='line'>
                        <Row justify="center">
                            <Col xs={8} sm={12} md={8} lg={8} xl={8}>
                                <img src="http://qiniu.metridynamic.com/image/new-official-en/oblique/%E9%AB%98%E6%80%A7%E4%BB%B7%E6%AF%94.png" alt={""}/>
                                <p>Cost-effective</p>
                            </Col>
                            <Col xs={8} sm={12} md={8} lg={8} xl={8}>
                                <img src="http://qiniu.metridynamic.com/image/new-official-en/oblique/1.2%E4%BA%BF%E5%83%8F%E7%B4%A0%E8%8B%B1%E6%96%87.png" alt={""}/>
                                <p>centimiter-level precision </p>
                            </Col>
                            <Col xs={8} sm={12} md={8} lg={8} xl={8}>
                                <img src="http://qiniu.metridynamic.com/image/new-official-en/oblique/%E6%95%B0%E6%8D%AE%E9%A2%84%E5%A4%84%E7%90%86.png" alt={""}/>
                                <p>Interchangeable</p>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

        )

    }
}
