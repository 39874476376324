import react from "react";
import "./style.less"
import 'rc-texty/assets/index.css';
import {Card, Carousel} from "antd";

const {Meta} = Card;

export default class MobileContent0 extends react.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="Mobile-datalink-content0">
                <Carousel autoplay>
                    <Card
                        style={{width: 240, padding: "0 12px"}}
                        cover={<img alt="example" style={{width: 330, margin: "0 auto"}}
                                    src="http://qiniu.metridynamic.com/image/datalink/d1.png"/>}
                    >
                        <Meta title="Directional antenna"
                              description="When the automatic tracking directional antenna is working, it will always face the direction of the aircraft to ensure the best communication signal strength. It is optional when it is above 50km."/>
                    </Card>
                    <Card

                        style={{width: 240}}
                        cover={<img alt="example" style={{width: 330, margin: "0 auto"}}
                                    src="http://qiniu.metridynamic.com/image/datalink/d2.png"/>}
                    >
                        <Meta title="Omni antenna"
                              description="When the omni-directional antenna is working, it will send and receive signals in a 360-degree direction. In the long-distance communication, the signal strength will be relatively weak. It is suitable for the communication range of 30km and below."/>
                    </Card>

                </Carousel>
            </div>
        )
    }
}
