import React from "react";
import TweenOne from "rc-tween-one";
import { Menu, Image, Badge } from "antd";
import { Link } from "react-router-dom";
import { getChildrenToRender } from "../../utils";
import "./header.less";

const { Item, SubMenu } = Menu;

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneOpen: undefined,
            currentKey: "",
        };
    }

    phoneClick = () => {
        const phoneOpen = !this.state.phoneOpen;
        this.setState({
            phoneOpen,
        });
    };
    handleClick = ({ item, key }) => {
        this.setState({
            currentKey: key,
        });
    };

    toChinese() {
        location.href = "http://cn.metridynamic.com/";
    }

    render() {
        const { dataSource, isMobile, ...props } = this.props;
        const { phoneOpen } = this.state;
        const navData = dataSource.Menu.children; //菜单集合
        const navChildren = navData.map((item) => {
            const { children: a, subItem, ...itemProps } = item;
            if (subItem) {
                console.log(a)
                return (
                    <Menu.SubMenu
                        key={item.name}
                        {...itemProps}
                        title={
                            a.children[0].children === "PRODUCTS" ?
                            <div
                                {...a}
                                className={`header0-item-block ${a.className}`.trim()}
                            >
                                <Badge size="small" count={'New'} offset={[4, -7]}>
                                    <div style={{ color: "#fff" }}>
                                        {a.children.map(getChildrenToRender)}
                                    </div>
                                </Badge>
                            </div>:<div
                                {...a}
                                className={`header0-item-block ${a.className}`.trim()}
                            >
                                    <div>
                                        {a.children.map(getChildrenToRender)}
                                    </div>
                            </div>
                        }
                        popupClassName="header0-item-child"
                    >
                        {subItem.map(($item, ii) => {
                            const { children: b, subItem, ...itemProps } = $item;
                            if (subItem) {
                                //有二级菜单
                                return (
                                    <Menu.SubMenu
                                        key={subItem.name}
                                        {...itemProps}
                                        title={
                                            b.children[0].children === "Payload" ? (
                                                <div
                                                    {...b}
                                                    className={`header0-item-block ${b.className}`.trim()}
                                                >
                                                    <Badge size="small" count={'New'} offset={[4, -7]}>
                                                        {b.children.map(getChildrenToRender)}
                                                    </Badge>
                                                </div>
                                            ) : (
                                                <div
                                                    {...b}
                                                    className={`header0-item-block ${b.className}`.trim()}
                                                >
                                                    {b.children.map(getChildrenToRender)}
                                                </div>
                                            )
                                        }
                                        popupClassName="header0-item-child"
                                    >
                                        {subItem.map(($item, ii) => {
                                            const { children: c, subItem, ...itemProps } = $item;
                                            const child = c.href ? (
                                                <Link {...c}>
                                                    {c.children.map(getChildrenToRender)}
                                                </Link>
                                            ) : (
                                                <div {...c}>{c.children.map(getChildrenToRender)}</div>
                                            );
                                            console.log(child)
                                            return child.props.children[0].props.children==="Latest Products"?(
                                                <Menu.Item key={$item.name || ii.toString()} {...$item}>
                                                    <Badge size="small" count={'New'} offset={isMobile?[4, 4]:[-18, -6]}>
                                                        {child}
                                                    </Badge>
                                                </Menu.Item>
                                            ):(
                                                <Menu.Item key={$item.name || ii.toString()} {...$item}>
                                                    {child}
                                                </Menu.Item>
                                            );
                                        })}
                                    </Menu.SubMenu>
                                );
                            } else {
                                const child = b.href ? (
                                    <Link {...b}>{b.children.map(getChildrenToRender)}</Link>
                                ) : (
                                    <div {...b}>{b.children.map(getChildrenToRender)}</div>
                                );
                                return (
                                    <Menu.Item key={$item.name || ii.toString()} {...$item}>
                                        {child}
                                    </Menu.Item>
                                );
                            }
                        })}
                    </Menu.SubMenu>
                );
            }
            return (
                <Item key={item.name} {...itemProps}>
                    <a {...a} className={`header0-item-block ${a.className}`.trim()}>
                        {/*<NavLink to={item.children.href}>*/}
                        {a.children.map(getChildrenToRender)}
                        {/*</NavLink>*/}
                    </a>
                </Item>
            );
        });
        const moment = phoneOpen === undefined ? 300 : null;
        return (
            <TweenOne
                component="header"
                animation={{ opacity: 0, type: "from" }}
                {...dataSource.wrapper}
                {...props}
            >
                {isMobile && (
                    <a href="/contact" class="header0-item-block getaquote">
                        <div name="text">GET A QUOTE</div>
                    </a>
                )}
                <div
                    {...dataSource.page}
                    className={`${dataSource.page.className}${phoneOpen ? " open" : ""}`}
                >
                    {isMobile && (
                        <Image
                            className="chinese"
                            onClick={this.toChinese}
                            preview={false}
                            src="https://qiniu.metridynamic.com/image/20220610-145302.png"
                        />
                    )}
                    <TweenOne
                        animation={{ x: -30, type: "from", ease: "easeOutQuad" }}
                        {...dataSource.logo}
                    >
                        <img width="100%" src={dataSource.logo.children} alt="img" />
                    </TweenOne>
                    {isMobile && (
                        <div
                            {...dataSource.mobileMenu}
                            onClick={() => {
                                this.phoneClick();
                            }}
                        >
                            <em />
                            <em />
                            <em />
                        </div>
                    )}
                    <TweenOne
                        animation={{ x: -30, type: "from", ease: "easeOutQuad" }}
                        className="online-demo"
                    ></TweenOne>
                    <TweenOne
                        {...dataSource.Menu}
                        animation={
                            isMobile
                                ? {
                                    height: 0,
                                    duration: 300,
                                    onComplete: (e) => {
                                        if (this.state.phoneOpen) {
                                            e.target.style.height = "auto";
                                        }
                                    },
                                    ease: "easeInOutQuad",
                                }
                                : null
                        }
                        moment={moment}
                        reverse={!!phoneOpen}
                    >
                        <a href="/contact" class="header0-item-block getaquote">
                            <div name="text">GET A QUOTE</div>
                        </a>
                        <Menu
                            mode={isMobile ? "inline" : "horizontal"}
                            onClick={this.handleClick}
                            selectedKeys={this.state.currentKey}
                            triggerSubMenuAction="hover"
                        >
                            {navChildren}
                        </Menu>
                        {!isMobile && (
                            <Image
                                className="chinese"
                                onClick={this.toChinese}
                                preview={false}
                                src="https://qiniu.metridynamic.com/image/20220610-145302.png"
                            />
                        )}
                    </TweenOne>
                </div>
            </TweenOne>
        );
    }
}

export default Header;
