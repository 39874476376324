import React from 'react'
import './style.less'
import { Table, Tag, Space } from 'antd';

export default class ParametersContent extends React.Component{
    render() {
        const columns = [
            {
                title: 'Specification',
                dataIndex: 'Specification',
                key: 'Specification',
                align:"center"
            },
            {
                title: '',
                dataIndex: 'param',
                key: 'param',
                align:"center"
            },
        ];

        const data = [
            {
                key: '1',
                Specification: 'Working frequency',
                param: "1.4GHz/2.4GHz(or customized)",
            },
            {
                key: '2',
                Specification: 'Channel bandwidth',
                param: "20MHz",
            },
            {
                key: '3',
                Specification: 'Radio frequency transmitting power',
                param: "2W(50km)、4W(100km)",
            },
            {
                key: '4',
                Specification: 'Radio frequency receiving sensitivity',
                param: "-90dBm",
            },
            {
                key: '5',
                Specification: 'Effective transmission distance',
                param: "30km/50km/100km/150km (LOS) ",
            },
            {
                key: '6',
                Specification: 'Modulation mode',
                param: "QPSK",
            },
            {
                key: '7',
                Specification: 'Link rate',
                param: "10Mbps",
            },
            {
                key: '8',
                Specification: 'HD The minimum delay of the image terminal',
                param: "250ms",
            },
            {
                key: '9',
                Specification: 'Cold start time',
                param: "5s",
            },
            {
                key: '10',
                Specification: 'Link establishment time',
                param: "10 ms",
            },
            {
                key: '11',
                Specification: 'Operating temperature',
                param: "-35°C~+60°C",
            },
        ];
        return (
            <div className={'parameterContent'}>
                <Table columns={columns} dataSource={data} pagination={false}/>
            </div>
        )
    }
}
