import React from "react";
import {BrowserRouter,Route,Switch,Redirect} from "react-router-dom";
import App from "./App";
import Home from "./pages/home";
import FlyingPlatform from "./pages/flyingPlatform";
import Simulator from "./pages/simulator";
import Gimbal from "./pages/gimbal";
import Payload from "./pages/payload";
import Ground from "./pages/groundEnd";
import ObliqueCamera from "./pages/obliqueCamera";
import OrthographicCamera from "./pages/orthographicCamera";
import Lidar from "./pages/Lidar";
import Datalink from "./pages/Datalink";
import Insurance from "./pages/insurance";
import Training from "./pages/training";
import Service from "./pages/service";
import AboutUs from "./pages/about";
import JoinUs from "./pages/joinus";
import GCS from "./pages/GCS";
import ContactUs from "./pages/contactUs";
import OnlineDemo from "./pages/onlineDemo"
import LatestProducts from "./pages/latestProducts"
export default class MyRouter extends React.Component{
    render() {
        return(
            <BrowserRouter>
                <App>
                    <Switch>
                        <Route path='/' render={()=>
                            <Switch>
                                <Route path='/home' component={Home}/>
                                <Route path='/payload' component={Payload}/>
                                <Route path='/ground' component={Ground}/>
                                <Route path='/aircraft' component={FlyingPlatform}/>
                                <Route path='/simulator' component={Simulator}/>
                                <Route path='/gimbal' component={Gimbal}/>
                                <Route path='/oblique' component={ObliqueCamera}/>
                                <Route path='/orthographic' component={OrthographicCamera}/>
                                <Route path='/lidar' component={Lidar}/>
                                <Route path='/datalink' component={Datalink}/>
                                <Route path='/GCS' component={GCS}/>
                                <Route path='/insurance' component={Insurance}/>
                                <Route path='/training' component={Training}/>
                                <Route path='/service' component={Service}/>
                                <Route path='/aboutUs' component={AboutUs}/>
                                <Route path='/join' component={JoinUs}/>
                                <Route path='/contact' component={ContactUs}/>
                                <Route path='/onlineDemo' component={OnlineDemo}/>
                                <Route path='/latestProducts' component={LatestProducts}/>
                                <Redirect to={'/home'}/>
                            </Switch>
                        }/>
                    </Switch>
                </App>
            </BrowserRouter>
        )
    }
}
