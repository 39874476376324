import PxLoader from "./../../../../static/js/PxLoader"
import "./../../../../static/js/PxLoaderImage"

export default class PicLoader {
    constructor(props) {
        this.images = []
        this.loadImage()
    }

    loadImage() {
        const html = document.documentElement;
        const loader = new PxLoader();
        for (let i = 0; i < 210; i++) {
            this.images[i] = loader.addImage(`http://qiniu.metridynamic.com/image/new-official-en/P4pages/00${i.toString().padStart(4, "0")}.jpg`)
        }
        loader.addCompletionListener(() => {
            const canvas = document.querySelector('.p4-scrolling');
            if (canvas) {
                const context = canvas.getContext('2d');
                context.drawImage(this.images[0], 0, 0, 1200, 720);
            }
        })
        loader.start()
        window.addEventListener('scroll', () => {
            let scrolled = document.documentElement.scrollTop / (document.documentElement.scrollHeight - document.documentElement.clientHeight)
            let frame = Math.ceil(scrolled * 209)
            this.updateImage(frame)
        })
    }

    updateImage = frame => {
        let index = frame - 1
        if (index < 0) index = 0;
        if (index > 209) index = 209;
        const canvas = document.querySelector('.p4-scrolling');
        if (canvas) {
            const context = canvas.getContext('2d');
            context.drawImage(this.images[index], 0, 0, 1200, 720);
        }
    }
}
