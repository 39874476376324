import React from 'react';

export const Nav00DataSource = {
    wrapper: {className: 'header0 home-page-wrapper'},
    page: {className: 'home-page'},
    logo: {
        className: 'header0-logo',
        children: 'http://qiniu.metridynamic.com/image/logo-word-white.png',
    },
    Menu: {
        className: 'header0-menu',
        children: [
            {
                name: 'item-1',
                className: 'header0-item-iwantademo',
                children: {
                    href: '/onlineDemo',
                    children: [{ children: 'I WANT A DEMO', name: 'text' }],
                    className: 'iwantademo'
                },
            },
            {
                name: 'item0',
                className: 'header0-item',
                children: {
                    href: '/home',
                    children: [{children: 'HOME', name: 'text'}],
                },
            },
            {
                name: 'item1',
                className: 'header0-item',
                children: {
                    href: '#',
                    children: [{children: 'PRODUCTS', name: 'text'}],
                },
                subItem: [
                    {
                        name: 'item-sub0',
                        className: 'item-sub',
                        children: {
                            className: 'item-sub-item',
                            children: [
                                {
                                    href: '/aircraft',
                                    name: 'aircraft',
                                    className: 'item-title',
                                    children: 'Aircraft',
                                },
                            ],
                        },
                    },
                    {
                        name: 'item-sub1',
                        className: 'item-sub',
                        children: {
                            className: 'item-sub-item',
                            children: [
                                {
                                    href: '/payload',
                                    name: 'payload',
                                    className: 'item-title',
                                    children: 'Payload',
                                },
                            ],
                        },
                        subItem: [
                            {
                                name: 'item-sub10',
                                className: 'item-sub',
                                children: {
                                    className: 'item-sub-item',
                                    children: [
                                        {
                                            href: '/latestProducts',
                                            name: 'latestProducts',
                                            className: 'item-title',
                                            children: 'Latest Products',
                                        },
                                    ],
                                },
                            },
                            {
                                name: 'item-sub11',
                                className: 'item-sub',
                                children: {
                                    className: 'item-sub-item',
                                    children: [
                                        {
                                            href: '/oblique',
                                            name: 'oblique',
                                            className: 'item-title',
                                            children: 'Oblique Camera',
                                        },
                                    ],
                                },
                            },
                            {
                                name: 'item-sub12',
                                className: 'item-sub',
                                children: {
                                    className: 'item-sub-item',
                                    children: [
                                        {
                                            href: '/gimbal',
                                            name: 'gimbal',
                                            className: 'item-title',
                                            children: 'Gimbal',
                                        },
                                    ],
                                },
                            },
                            {
                                name: 'item-sub13',
                                className: 'item-sub',
                                children: {
                                    className: 'item-sub-item',
                                    children: [
                                        {
                                            href: '/orthographic',
                                            name: 'orthographic',
                                            className: 'item-title',
                                            children: 'Orthographic Camera',
                                        },
                                    ],
                                },
                            },
                            {
                                name: 'item-sub14',
                                className: 'item-sub',
                                children: {
                                    className: 'item-sub-item',
                                    children: [
                                        {
                                            href: '/lidar',
                                            name: 'lidar',
                                            className: 'item-title',
                                            children: 'LIDAR',
                                        },
                                    ],
                                },
                            }
                        ]
                    },
                    {
                        name: 'item-sub2',
                        className: 'item-sub',
                        children: {
                            className: 'item-sub-item',
                            children: [
                                {
                                    href: '/ground',
                                    name: 'ground',
                                    className: 'item-title',
                                    children: 'Ground',
                                },
                            ],
                        },
                        subItem: [
                            {
                                name: 'item-sub21',
                                className: 'item-sub',
                                children: {
                                    className: 'item-sub-item',
                                    children: [
                                        {
                                            href: '/datalink',
                                            name: 'datalink',
                                            className: 'item-title',
                                            children: 'Datalink',
                                        },
                                    ],
                                },
                            },
                            {
                                name: 'item-sub22',
                                className: 'item-sub',
                                children: {
                                    className: 'item-sub-item',
                                    children: [
                                        {
                                            href: '/GCS',
                                            name: 'GCS',
                                            className: 'item-title',
                                            children: 'Ground Control Station',
                                        },
                                    ],
                                },
                            },
                            {
                                name: 'item-sub23',
                                className: 'item-sub',
                                children: {
                                    className: 'item-sub-item',
                                    children: [
                                        {
                                            href: '/simulator',
                                            name: 'simulator',
                                            className: 'item-title',
                                            children: 'Simulator',
                                        },
                                    ],
                                },
                            }
                        ]
                    },
                ],
            },
            {
                name: 'item4',
                className: 'header0-item',
                children: {
                    href: '#',
                    children: [{children: 'SUPPORT', name: 'text'}],
                },
                subItem: [
                    {
                        name: 'sub0',
                        // className: 'item-sub',
                        children: {
                            className: 'item-sub-item',
                            children: [
                                {
                                    href: '/insurance',
                                    name: 'insurance',
                                    className: 'item-title',
                                    children: 'Insurance',
                                },
                            ],
                        },
                    },
                    {
                        name: 'sub2',
                        className: 'item-sub',
                        children: {
                            className: 'item-sub-item',
                            children: [
                                {
                                    name: 'title',
                                    className: 'item-title',
                                    href: '/training',
                                    children: 'Training',
                                },
                            ],
                        },
                    },
                    {
                        name: 'sub3',
                        className: 'item-sub',
                        children: {
                            className: 'item-sub-item',
                            children: [
                                {
                                    name: 'title',
                                    className: 'item-title',
                                    href: '/service',
                                    children: 'Service',
                                },
                            ],
                        },
                    },
                ],
            },
            {
                name: 'item5',
                className: 'header0-item',
                children: {
                    href: '/aboutUs',
                    children: [{children: 'ABOUT US', name: 'text', href: '/aboutUs',}],
                },
            },
            {
                name: 'item6',
                className: 'header0-item',
                children: {
                    href: '/join',
                    children: [{children: 'JOIN US', name: 'text'}],
                },
            },
            {
                name: 'item7',
                className: 'header0-item',
                children: {
                    href: '/contact',
                    children: [{children: 'CONTACT US', name: 'text'}],
                },
            },
        ],
    },
    mobileMenu: {className: 'header0-mobile-menu'},
};
export const Banner01DataSource = {
    wrapper: {className: 'banner0'},
    textWrapper: {className: 'banner0-text-wrapper'},
    title: {
        className: 'banner0-title',
        children: 'http://qiniu.metridynamic.com/image/logo/logo_words_white.png',
    },
    content: {
        className: 'banner0-content',
        children: 'Make Sky as the Infrastructure of Living and Working Activities of Human Beings',
    },
    button: {className: 'banner0-button', children: 'Learn More'},
};
export const Content00DataSource = {
    wrapper: {className: 'home-page-wrapper content0-wrapper'},
    page: {className: 'home-page content0'},
    OverPack: {playScale: 0.3, className: ''},
    titleWrapper: {
        className: 'title-wrapper',
        children: [{name: 'title', children: 'Products'}],
    },
    childWrapper: {
        className: 'content0-block-wrapper',
        children: [
            {
                name: 'block0',
                className: 'content0-block',
                md: 6,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [
                        {
                            name: 'image',
                            className: 'content0-block-icon',
                            children:
                                'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
                        },
                        {
                            name: 'title',
                            className: 'content0-block-title',
                            children: '一站式业务接入',
                        },
                        {name: 'content', children: '支付、结算、核算接入产品效率翻四倍'},
                    ],
                },
            },
            {
                name: 'block1',
                className: 'content0-block',
                md: 6,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [
                        {
                            name: 'image',
                            className: 'content0-block-icon',
                            children:
                                'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
                        },
                        {
                            name: 'title',
                            className: 'content0-block-title',
                            children: '一站式事中风险监控',
                        },
                        {
                            name: 'content',
                            children: '在所有需求配置环节事前风险控制和质量控制能力',
                        },
                    ],
                },
            },
            {
                name: 'block2',
                className: 'content0-block',
                md: 6,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [
                        {
                            name: 'image',
                            className: 'content0-block-icon',
                            children:
                                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
                        },
                        {
                            name: 'title',
                            className: 'content0-block-title',
                            children: '一站式数据运营',
                        },
                        {
                            name: 'content',
                            children: '沉淀产品接入效率和运营小二工作效率数据',
                        },
                    ],
                },
            },
            {
                name: 'block3',
                className: 'content0-block',
                md: 6,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [
                        {
                            name: 'image',
                            className: 'content0-block-icon',
                            children:
                                'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
                        },
                        {
                            name: 'title',
                            className: 'content0-block-title',
                            children: '一站式业务接入',
                        },
                        {name: 'content', children: '支付、结算、核算接入产品效率翻四倍'},
                    ],
                },
            },
            {
                name: 'block4',
                className: 'content0-block',
                md: 6,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [
                        {
                            name: 'image',
                            className: 'content0-block-icon',
                            children:
                                'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
                        },
                        {
                            name: 'title',
                            className: 'content0-block-title',
                            children: '一站式事中风险监控',
                        },
                        {
                            name: 'content',
                            children: '在所有需求配置环节事前风险控制和质量控制能力',
                        },
                    ],
                },
            },
            {
                name: 'block5',
                className: 'content0-block',
                md: 6,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [
                        {
                            name: 'image',
                            className: 'content0-block-icon',
                            children:
                                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
                        },
                        {
                            name: 'title',
                            className: 'content0-block-title',
                            children: '一站式数据运营',
                        },
                        {
                            name: 'content',
                            children: '沉淀产品接入效率和运营小二工作效率数据',
                        },
                    ],
                },
            },
            {
                name: 'block6',
                className: 'content0-block',
                md: 6,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [
                        {
                            name: 'image',
                            className: 'content0-block-icon',
                            children:
                                'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
                        },
                        {
                            name: 'title',
                            className: 'content0-block-title',
                            children: '一站式事中风险监控',
                        },
                        {
                            name: 'content',
                            children: '在所有需求配置环节事前风险控制和质量控制能力',
                        },
                    ],
                },
            },
            {
                name: 'block7',
                className: 'content0-block',
                md: 6,
                xs: 24,
                children: {
                    className: 'content0-block-item',
                    children: [
                        {
                            name: 'image',
                            className: 'content0-block-icon',
                            children:
                                'https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png',
                        },
                        {
                            name: 'title',
                            className: 'content0-block-title',
                            children: '一站式数据运营',
                        },
                        {
                            name: 'content',
                            children: '沉淀产品接入效率和运营小二工作效率数据',
                        },
                    ],
                },
            },
        ],
    },
};
export const Content50DataSource = {
    wrapper: {className: 'home-page-wrapper-cases'},
    page: {className: 'home-page-cases content5'},
    OverPack: {playScale: 0.3, className: ''},
    titleWrapper: {
        className: 'title-wrapper-case',
        children: [
            {name: 'title', children: 'VARIOUS APPLICATIONS', className: 'title-h1'},
            {
                name: 'content',
                className: 'title-content',
                children: '',
            },
        ],
    },
    block: {
        className: 'content5-img-wrapper',
        gutter: 16,
        children: [
            {
                name: 'block0',
                className: 'trade-box-item item01',
                md: 6,
                xs: 24,
                children: {
                    wrapper: {className: 'content5-block-content'},
                    img: {
                        children:
                            'http://qiniu.metridynamic.com/image/new-official-en/CASES/forest-fire-prevention.png',
                    },
                    content: {children: 'ENVIROMENT PROTECTION'},

                },
            },
            {
                name: 'block1',
                className: 'trade-box-item item02',
                md: 6,
                xs: 24,
                children: {
                    wrapper: {className: 'content5-block-content'},
                    img: {
                        children:
                            'http://qiniu.metridynamic.com/image/new-official-en/CASES/electricity.png',
                    },
                    content: {children: 'ELECTRICITY'},
                },
            },
            {
                name: 'block2',
                className: 'trade-box-item item03',
                md: 6,
                xs: 24,
                children: {
                    wrapper: {className: 'content5-block-content'},
                    img: {
                        children:
                            'http://qiniu.metridynamic.com/image/new-official-en/CASES/securty.png',
                    },
                    content: {children: 'SECURITY'},
                },
            },
            {
                name: 'block3',
                className: 'trade-box-item item04',
                md: 6,
                xs: 24,
                children: {
                    wrapper: {className: 'content5-block-content'},
                    img: {
                        children:
                            'http://qiniu.metridynamic.com/image/new-official-en/CASES/mapping.png',
                    },
                    content: {children: 'MAPPING'},
                },
            },
            {
                name: 'block4',
                className: 'trade-box-item item05',
                md: 6,
                xs: 24,
                children: {
                    wrapper: {className: 'content5-block-content'},
                    img: {
                        children:
                            'http://qiniu.metridynamic.com/image/new-official-en/CASES/securty.png',
                    },
                    content: {children: 'AGRICULTURE'},
                },
            }
        ],
    },
};
export const Content30DataSource = {
    wrapper: {className: 'home-page-wrapper content3-wrapper'},
    page: {className: 'home-page content3'},
    OverPack: {playScale: 0.3},
    titleWrapper: {
        className: 'title-wrapper',
        children: [
            {
                name: 'title',
                children: '蚂蚁金融云提供专业的服务',
                className: 'title-h1',
            },
            {
                name: 'content',
                className: 'title-content',
                children: '基于阿里云强大的基础资源',
            },
        ],
    },
    block: {
        className: 'content3-block-wrapper',
        children: [
            {
                name: 'block0',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children:
                            'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
                    },
                    textWrapper: {className: 'content3-text'},
                    title: {className: 'content3-title', children: '企业资源管理'},
                    content: {
                        className: 'content3-content',
                        children:
                            '云资源集中编排、弹性伸缩、持续发布和部署，高可用及容灾。',
                    },
                },
            },
            {
                name: 'block1',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children:
                            'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
                    },
                    textWrapper: {className: 'content3-text'},
                    title: {className: 'content3-title', children: '云安全'},
                    content: {
                        className: 'content3-content',
                        children:
                            '按金融企业安全要求打造的完整云上安全体系，全方位保障金融应用及数据安全。',
                    },
                },
            },
            {
                name: 'block2',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children:
                            'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
                    },
                    textWrapper: {className: 'content3-text'},
                    title: {className: 'content3-title', children: '云监控'},
                    content: {
                        className: 'content3-content',
                        children:
                            '分布式云环境集中监控，统一资源及应用状态视图，智能分析及故障定位。',
                    },
                },
            },
            {
                name: 'block3',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children:
                            'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
                    },
                    textWrapper: {className: 'content3-text'},
                    title: {className: 'content3-title', children: '移动'},
                    content: {
                        className: 'content3-content',
                        children:
                            '一站式移动金融APP开发及全面监控；丰富可用组件，动态发布和故障热修复。',
                    },
                },
            },
            {
                name: 'block4',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children:
                            'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
                    },
                    textWrapper: {className: 'content3-text'},
                    title: {className: 'content3-title', children: '分布式中间件'},
                    content: {
                        className: 'content3-content',
                        children:
                            '金融级联机交易处理中间件，大规模分布式计算机，数万笔/秒级并发能力，严格保证交易数据统一性。',
                    },
                },
            },
            {
                name: 'block5',
                className: 'content3-block',
                md: 8,
                xs: 24,
                children: {
                    icon: {
                        className: 'content3-icon',
                        children:
                            'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
                    },
                    textWrapper: {className: 'content3-text'},
                    title: {className: 'content3-title', children: '大数据'},
                    content: {
                        className: 'content3-content',
                        children:
                            '一站式、全周期大数据协同工作平台，PB级数据处理、毫秒级数据分析工具。',
                    },
                },
            },
        ],
    },
};
export const Footer10DataSource = {
    wrapper: {className: 'home-page-wrapper footer1-wrapper'},
    OverPack: {className: 'footer1', playScale: 0.2},
    block: {
        className: 'home-page',
        gutter: 0,
        children: [
            {
                name: 'block0',
                xs: 24,
                md: 6,
                className: 'block',
                title: {
                    className: 'logo',
                    children:
                        'http://qiniu.metridynamic.com/image/logo-word-white.png',
                },
                childWrapper: {
                    className: 'slogan',
                    children: [
                        {
                            name: 'content0',
                            children: 'Make Sky as the Infrastructure of Living and Working Activities of Human Beings.',
                        },
                    ],
                },
            },
            {
                name: 'block1',
                xs: 24,
                md: 6,
                className: 'block',
                title: {children: 'PRODUCTS'},
                childWrapper: {
                    children: [
                        {name: 'link0', href: '/aircraft', children: 'Aircraft'},
                        {name: 'link1', href: '/payload', children: 'Payload'},
                        {name: 'link2', href: '/ground', children: 'Ground'},
                    ],
                },
            },
            // {
            //   name: 'block2',
            //   xs: 24,
            //   md: 4,
            //   className: 'block',
            //   title: { children: 'PAYLOAD' },
            //   childWrapper: {
            //     children: [
            //       { href: '#', name: 'link0', children: 'Gimbal' },
            //       { href: '#', name: 'link1', children: 'Oblique Camera' },
            //       { href: '#', name: 'link2', children: 'Orthgraphic Camera' },
            //       { href: '#', name: 'link3', children: 'LiDAR' },
            //       { href: '#', name: 'link4', children: 'Multispectral Camera' },
            //       { href: '#', name: 'link5', children: 'Gas Detector' },
            //     ],
            //   },
            // },
            // {
            //   name: 'block3',
            //   xs: 24,
            //   md: 4,
            //   className: 'block',
            //   title: { children: 'GROUNDCONTROL' },
            //   childWrapper: {
            //     children: [
            //       { href: '#', name: 'link0', children: 'GCS' },
            //       { href: '#', name: 'link1', children: 'DataLink' },
            //     ],
            //   },
            // },
            {
                name: 'block2',
                xs: 24,
                md: 6,
                className: 'block',
                title: {children: 'SUPPORT'},
                childWrapper: {
                    children: [
                        {href: '/insurance', name: 'link0', children: 'Insurance'},
                        {href: '/training', name: 'link1', children: 'Training'},
                        {href: '/service', name: 'link2', children: 'Service'},
                    ],
                },
            },
            {
                name: 'block3',
                xs: 24,
                md: 6,
                className: 'block',
                title: {children: 'ABOUT US'},
                childWrapper: {
                    children: [
                        {href: '/aboutUs', name: 'link0', children: 'About Us'},
                        {href: '/join', name: 'link1', children: 'Join Us'},
                        {href: '/contact', name: 'link2', children: 'Contact Us'},
                    ],
                },
            },
        ],
    },
    copyrightWrapper: {className: 'copyright-wrapper'},
    copyrightPage: {className: 'home-page'},
    copyright: {
        className: 'copyright',
        children: (
            <span>
              <a href="https://beian.miit.gov.cn/">蜀ICP备2022001559号-2</a> ©2022 by Metridynamic
            </span>
          ),
          children1: (
            <span>
              <a href="https://beian.miit.gov.cn/">蜀ICP备2022001559号-1</a> ©2022 by Metridynamic
            </span>
          )
    },
};
export const ContentAircraftDataSource = {
    wrapper: {className: 'home-page-wrapper aircraft-info-wrapper'},
    page: {className: 'aircraft-info'},
    OverPack: {playScale: 0.3, className: ''},
    titleWrapper: {
        className: 'title-wrapper',
        children: [
            {name: 'title', children: '', className: 'title-h1'},
            {
                name: 'content',
                className: 'title-content',
                children: 'Unique technology',
            },
        ],
    },
    block: {
        className: 'content-aircraft-wrapper',
        gutter: 16,
        children: [
            // {
            //   name: 'block0',
            //   className: 'block',
            //   md: 6,
            //   xs: 24,
            //   children: {
            //     wrapper: { className: 'content5-block-content' },
            //     img: {
            //       children:
            //           'https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg',
            //     },
            //     content: { children: 'Ant Design' },
            //   },
            // },
            // {
            //   name: 'block1',
            //   className: 'block',
            //   md: 6,
            //   xs: 24,
            //   children: {
            //     wrapper: { className: 'content5-block-content' },
            //     img: {
            //       children:
            //           'https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg',
            //     },
            //     content: { children: 'Ant Motion' },
            //   },
            // },
            // {
            //   name: 'block2',
            //   className: 'block',
            //   md: 6,
            //   xs: 24,
            //   children: {
            //     wrapper: { className: 'content5-block-content' },
            //     img: {
            //       children:
            //           'https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg',
            //     },
            //     content: { children: 'Ant Design' },
            //   },
            // },
            // {
            //   name: 'block3',
            //   className: 'block',
            //   md: 6,
            //   xs: 24,
            //   children: {
            //     wrapper: { className: 'content5-block-content' },
            //     img: {
            //       children:
            //           'https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg',
            //     },
            //     content: { children: 'Ant Motion' },
            //   },
            // },
            // {
            //   name: 'block4',
            //   className: 'block',
            //   md: 6,
            //   xs: 24,
            //   children: {
            //     wrapper: { className: 'content5-block-content' },
            //     img: {
            //       children:
            //           'https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg',
            //     },
            //     content: { children: 'Ant Design' },
            //   },
            // },
            // {
            //   name: 'block5',
            //   className: 'block',
            //   md: 6,
            //   xs: 24,
            //   children: {
            //     wrapper: { className: 'content5-block-content' },
            //     img: {
            //       children:
            //           'https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg',
            //     },
            //     content: { children: 'Ant Motion' },
            //   },
            // },
            // {
            //   name: 'block6',
            //   className: 'block',
            //   md: 6,
            //   xs: 24,
            //   children: {
            //     wrapper: { className: 'content5-block-content' },
            //     img: {
            //       children:
            //           'https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg',
            //     },
            //     content: { children: 'Ant Design' },
            //   },
            // },
            // {
            //   name: 'block7',
            //   className: 'block',
            //   md: 6,
            //   xs: 24,
            //   children: {
            //     wrapper: { className: 'content5-block-content' },
            //     img: {
            //       children:
            //           'https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg',
            //     },
            //     content: { children: 'Ant Motion' },
            //   },
            // },
        ],
    },
};
