import React from "react";
import QueueAnim from 'rc-queue-anim';
import './index.less'
import { Card, Row, Col, Collapse } from 'antd';
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import TweenOne from 'rc-tween-one';

const { Panel } = Collapse;
const { Meta } = Card;

export default class DatalinkContent1 extends React.Component {
    callback(key) {
        console.log(key);
    }

    render() {
        const cardStyle = {
            width: 320,
            padding: 8,
            margin: "0 auto"
        }
        return (
            <ScrollOverPack className='Datalink-content1'>
                <Row>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <TweenOne
                            key="image"
                            className="image-wrapper Datalink-content-image1"
                            animation={{ x: 0, opacity: 1, ease: 'easeOutQuad' }}
                            style={{ transform: 'translateX(-100px)', opacity: 0 }}
                            title="Onboard/ground processing unit"
                        />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <QueueAnim
                            key="QueueAnim"
                            type={['bottom', 'top']}
                            delay={200}
                            className='text-wrapper Datalink-text'
                        >
                            <p>The most practical transmission solution, long enough and reliable enough. </p>
                            <p><span>· </span>Integrated transmission for image, data, control </p>
                            <p><span>· </span>Radio ranging and locating incase of GNSS lost</p>
                            <p><span>· </span>Automatic channal hopping in situation of interference</p>
                            <p><span>· </span>Real-time signal strength display and record</p>
                            <p><span>· </span>Point-to-point high-definition transmission, 1080P/60 fps video</p>
                        </QueueAnim>
                    </Col>
                </Row>


            </ScrollOverPack>
        )

    }
}
