import React from "react";
import './style.less';
import {Image} from "antd";

export default class TrainingContent extends React.Component{
    render() {
        return (
            <div className={'training-content'}>
                <div className="block">
                    <p>Comprehensive flight training service</p>
                    <h1>" Perseverance to training creates the glory of safety "</h1>
                    <h4>MetiDynamic Provide systematic and hierarchical
                        professional training
                        services for VTOL products and solutions, <br/>including basic knowledge of
                        fight planning and data acquisition,as well as training for specific<br/>
                            Industry applications. Help customers learn how to use aircraft correctly,
                            and how to maintain to keep them in best working condition.
                    </h4>
                <div className="image">

                </div>
                <h3>
                    <span>14</span> days operation training<br/>
                    <span>7</span> days simulator training
                </h3>
                </div>
            </div>
        )
        }
    }
