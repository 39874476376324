import React from "react";
import './style.less'
import { Row, Col, Image } from 'antd'
export default class GCSContent1 extends React.Component {
    render() {
        return (
            <div className="gcs-content1">
                <h2>MCM</h2>
                <Row justify="center">
                    <Col span="12">
                        <Image preview={false} src="http://qiniu.metridynamic.com/image/new-official-en/GCS/MCM.png" />
                    </Col>
                </Row>
            </div>
        )
    }
}