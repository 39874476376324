import React from 'react';
import PropTypes from 'prop-types';
import TweenOne from 'rc-tween-one';
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import {RightOutlined} from '@ant-design/icons';
import {Col, Row} from 'antd';
import QueueAnim from 'rc-queue-anim';
import {Link} from "react-router-dom";

export default function Page1({isMobile}) {
    return (
        <ScrollOverPack className="home-content-wrapper page">
            <Row justify="center">
                <Col className={"page-box"} xs={20} sm={16} md={12} lg={11} xl={11}>
                    <TweenOne
                        key="image"
                        className="payload1 image-wrapper"
                        animation={{x: 0, opacity: 1, ease: 'easeOutQuad'}}
                        style={{transform: 'translateX(-100px)', opacity: 0}}
                    />
                </Col>
                <Col className={"page-box"} xs={20} sm={16} md={12} lg={11} xl={11}>
                    <QueueAnim
                        type={isMobile ? 'bottom' : 'right'}
                        className="text-wrapper"
                        key="text"
                        leaveReverse
                    >
                        <h2 key="h2">"Fast Catch, Fast Modeling"</h2>
                        <p key="p" style={{maxWidth: 310, marginTop: 30, fontSize: "1.5rem"}}>Oblique Camera</p>
                        <p key="p" style={{maxWidth: 260, color: "rgb(0,138,214)"}}>DG3M/DG4M</p>
                        <div key="button">
                            <Link to="/oblique">
                                <span>Learn more</span>
                                <RightOutlined/>
                            </Link>
                        </div>
                    </QueueAnim>
                </Col>
            </Row>


        </ScrollOverPack>
    );
}
Page1.propTypes = {
    isMobile: PropTypes.bool,
};
