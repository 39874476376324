import React, { Component } from "react"

import Map from 'ol/Map';
import View from 'ol/View';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import XYZ from 'ol/source/XYZ'
import { Layer } from "ol/layer";
import Source from "ol/source/Source";
import Overlay from 'ol/Overlay';
import { Icon, Style } from 'ol/style';
import VectorSource from 'ol/source/Vector';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import OSM from 'ol/source/OSM';
export default class myMap {
    constructor(container) {

        //获取dom对象
        const containerbox = document.getElementById("popup");
        const content = document.getElementById("popup-content");
        const closer = document.getElementById("popup-closer");
        //创建Overlayer叠加层对象
        const overlay = new Overlay({
            element: containerbox,
            autoPanAnimation: {
                duration: 250
            }
        });
        overlay.setPosition([104.151517, 30.56045]);

        const iconFeature = new Feature({
            geometry: new Point([104.151517, 30.56045]),
            name: 'landing',
            population: 4000,
            rainfall: 500,
        });

        const iconStyle = new Style({
            image: new Icon({
                anchor: [0.5, 46],
                anchorXUnits: 'fraction',
                anchorYUnits: 'pixels',
                src: 'http://qiniu.metridynamic.com/image%2Fnew-official-en%2F20220524-091900.png',
            }),
        });

        iconFeature.setStyle(iconStyle);

        const vectorSource = new VectorSource({
            features: [iconFeature],
        });

        const vectorLayer = new VectorLayer({
            source: vectorSource,
        });
        /**
         * 地图基础层
         * @ @param {String} container 地图容器div id
         */
        this.map = new Map({
            view: new View({
                projection: 'EPSG:4326',
                zoom: 12,
                maxZoom: 21,
                center: [104.151517, 30.58045],
            }),
            layers: [
                new TileLayer({
                    title: 'arcgis',
                    source: new XYZ({
                        url: 'https://server.arcgisonline.com/ArcGIS/rest/services/' +
                        'World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
                    })
                }),
                vectorLayer
            ],
            overlays: [overlay],
            target: container
        });
        return this.map;
    }
}
