import React from "react";
import QueueAnim from 'rc-queue-anim';
import './index.less'
import { Col, Row } from "antd";
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';
export default class OrthographicBanner extends React.Component {
    componentDidMount() {
     

    }

    render() {
        return (
            <div className='Orthographic-banner'>
                <div className='Orthographic-banner-content'>
                    <h1><Texty>Small Body with Huge Energy</Texty></h1>
                    <div className="video-container video">
                    <video
                     x5-playsinline="true"
                     playsinline="true" 
                     webkit-playsinline="true"
                    src="http://qiniu.metridynamic.com/video/new%20video/payload/orthographic.mp4" autoPlay loop muted>

                    </video>
                </div>
                </div>

            </div>
        )

    }
}
