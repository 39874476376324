import React from 'react';
import PropTypes from 'prop-types';
import TweenOne from 'rc-tween-one';
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { Button, Row, Col } from 'antd';
import QueueAnim from 'rc-queue-anim';
import { Link } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";

export default function Page3({ isMobile }) {
    return (
        <ScrollOverPack id="page3" className="home-content-wrapper page">
            <Row justify="center">
                <Col className={"page-box"} xs={20} sm={16} md={12} lg={11} xl={11}>
                    <TweenOne
                        key="image"
                        className="payload3 image-wrapper"
                        animation={{ x: 0, opacity: 1, ease: 'easeOutQuad' }}
                        style={{ transform: 'translateX(-100px)', opacity: 0 }}
                    />
                </Col>
                <Col className={"page-box"} xs={20} sm={16} md={12} lg={11} xl={11}>
                    <QueueAnim
                        className="text-wrapper"
                        key="text"
                        type={isMobile ? 'bottom' : 'right'}
                        leaveReverse
                        style={{ top: '35%' }}
                    >
                        <h2 key="h2">"Small Body with Huge Energy"</h2>
                        <p key="p" style={{ maxWidth: 280,marginTop:30,fontSize:"1.5rem"  }}>
                            Orthographic Camera
                        </p>
                        <p key="p" style={{ maxWidth: 280,fontSize: "2rem", color: "rgb(0,138,214)" }}>
                            M6
                        </p>
                       
                        <div key="button">
                            <Link to={"/orthographic"}>
                            <span>Learn more</span>
                                <RightOutlined />
                            </Link>
                        </div>
                    </QueueAnim>
                </Col>
            </Row>
        </ScrollOverPack>
    );
}
Page3.propTypes = {
    isMobile: PropTypes.bool,
};
