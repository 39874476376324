import React from "react";
import './style.less';
import {Row, Col,Image} from "antd";

export default class TrainingContent1 extends React.Component{
    render() {
        return (
            <div className={'training-content1'}>
                <div className={'content-box'}>
                    <Row>
                        <Col xs={24} sm={24} md={6} lg={6} xl={6} className="imgCol">
                            {/* <Image preview={false} height={219} src={"http://qiniu.metridynamic.com/image/training/pilot.png"}/> */}
                        </Col>
                        <Col span="16" xs={{ span: 24 }} lg={{ span: 16}} className={"content-desc"}>
                            <p className={"title"}>Professional VTOL aircraft pilot</p>
                            <p className={"content"}>In order to meet the needs of P series
                                VTOL product test fight, delivery,
                                customer training,AOPA training,
                                etc. we have professional technclogy persornel
                                and maragers to provide systematic and hierarchical
                                professional trainning services for aircraft products and solutions</p>
                        </Col>
                    </Row>
                </div>
                <div className={"content-box"}>
                    <Row>
                        <Col xs={24} sm={24} md={6} lg={6} xl={6} className="imgCol2">
                            {/* <Image preview={false} height={246} src={"http://qiniu.metridynamic.com/image/training/site.png"}/> */}
                        </Col>
                        <Col span="16" className={"content-desc"} xs={{ span: 24 }} lg={{ span: 16}}>
                            <p className={"title"}>Fully-equipped flight base</p>
                            <p className={"content"}>The Aircraft Base for MetriDynamic is located in Qingbajiang District, Chengdu. The base covers an area of 1000 acres and is only 22 kilometers away from Chengdu. The base has converient transportation, graceful environment, completely infrastructure and many surrounding tourist attractions to meet various testing, demonstration, trainning and other needs</p>
                        </Col>
                    </Row>
                </div>
            </div>
        )
        }
    }
