import React from "react";
import './style.less'
import { Row, Col, Image, Card } from 'antd'
export default class ContactUs extends React.Component {
    componentDidMount() {

    }
    render() {
        const cardStyle = {
            width: 320,
            padding: 8,
            margin: "0 auto",
            background: "transparent",
            border: "none"
        }

        return (
            <div className="contact-us">
                <div className="title">

                </div>

                <div className="contact-us">
                    <Row className="box" gutter={32} justify="space-evenly">
                        <Col className="box-item" xs={24} sm={8} md={8} lg={8} xl={8}>
                            <div className="box-item-content">
                                <Image className="img1" preview={false}  src="http://qiniu.metridynamic.com/image/new-official-en/contactUs/20220411-094722.png" />
                                <h2>Contact us/Get a Quote</h2>
                                <p>Tel/Whatsapp: <a href="https://wa.me/8615001051230?text=Hi%20there,%20this%20is%20[your name],%20I'm%20interested%20in%20%20drones%20for%20[your use cases]">+86 15001051230</a></p>
                                <p>Email: <a href="mailto:sales@metridynamic.com?subject=VTOL drone enquiry from [your company name]&body=Hi there,this is [your name] from [your country], i'm interested in drones for [your use cases]">sales@metridynamic.com</a></p>
                            </div>
                        </Col>
                        {/* <Col className="box-item" xs={24} sm={8} md={8} lg={8} xl={8}>
                            <div className="box-item-content">
                                <Image preview={false}  src="http://qiniu.metridynamic.com/image/new-official-en/contactUs/20220411-094709.png" />
                                <h2>Official Accounts</h2>

                            </div>
                        </Col> */}
                        <Col className="box-item" xs={24} sm={8} md={8} lg={8} xl={8}>
                            <div className="box-item-content">
                                <Image className="img1" preview={false}  src="http://qiniu.metridynamic.com/image/new-official-en/contactUs/20220411-094717.png" />
                                <h2>Location</h2>
                                <p>Chengdu office: No. 2, Bld.50, Blue-roof Art Area Phase 2, Jinjiang District, Chengdu , China</p>
                                <p>Beijing office: Room 1011, Ruichen International Center, No. 13 Nongzhanguan South Road, Chaoyang District, Beijing</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}