import React from "react";
import QueueAnim from 'rc-queue-anim';
import './index.less'
import { Card, Row, Col, Collapse } from 'antd';
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import TweenOne from 'rc-tween-one';

const { Panel } = Collapse;
const { Meta } = Card;

export default class DatalinkContent2 extends React.Component {
    callback(key) {
        console.log(key);
    }

    render() {
        const cardStyle = {
            width: 320,
            padding: 8,
            margin: "0 auto"
        }
        return (
            <ScrollOverPack className='Datalink-content2'>
                <Row>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <QueueAnim
                            key="QueueAnim"
                            type={['bottom', 'top']}
                            delay={200}
                            className='text-wrapper Datalink-text'
                        >
                            
                            <div className="split-line"></div>
                            <p>Integrated design of the ground antenna </p>
                            <p><span>· </span>Both tracking and omni+directional antenna optional upon different range.</p>
                            <p><span>· </span>Single connector for both power and data transmission to ground control station </p>
                            <p><span>· </span>Omni+directional antenna is able to automatic switch between omni or directional upon the real-time distance of the aircraft</p>
                            <p><span>· </span>Tracking antenna is able to automatic detecting the precise direction of the aircraft and keep pointing towards it,  recommended for transmission over 50km. </p>
                        </QueueAnim>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <TweenOne
                            key="image"
                            className="image-wrapper Datalink-content-image2"
                            animation={{ x: 0, opacity: 1, ease: 'easeOutQuad' }}
                            style={{ transform: 'translateX(-100px)', opacity: 0 }}
                        />
                    </Col>
                </Row>
            </ScrollOverPack>
        )

    }
}
