import React from "react";
import QueueAnim from 'rc-queue-anim';
import './style.less'
import { Tabs, Radio, Space } from 'antd';
const { TabPane } = Tabs;

export default class DatalinkVideoContent extends React.Component {

    render() {
        return (
            <div className="Datalink-video-container">
                <h1>" Automatic tracking atenna <br></br> searching aircraft signal "</h1>
                <div className={"content"}>
                            <div className="video-container video">
                                <video 
                                 x5-playsinline="true"
                                 playsinline="true" 
                                 webkit-playsinline="true"
                                src="http://qiniu.metridynamic.com/video/datalink.mp4" autoPlay loop muted>

                                </video>
                            </div>
                </div>
            </div>
        )

    }
}
