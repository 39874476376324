import React from "react";
import './style.less'
import InsuranceBanner from "./components/banner";
import InsuranceContent0 from "./components/content0";
import HowToBuy from "./components/howToBuy";
import HowToMakeAClaim from "./components/HowToMakeAClaim";
export default class Insurance extends React.Component{
    render() {
        return (
            <div className={"insurance"}>
                <InsuranceBanner/>
                <InsuranceContent0/>
                <HowToBuy/>
                <HowToMakeAClaim/>
            </div>
        )
    }
}
