import react from "react";
import './style.less'
import {OverPack} from 'rc-scroll-anim';
import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';
import { Col } from "antd"; 
import { enquireScreen } from 'enquire-js';

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});
export default class TextContent extends react.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return !isMobile?(
            <div>
                <OverPack className="text-content">
                    <Col xs={24} sm={24} md={12} lg={12} xl={24}>
                        <QueueAnim key="queue" leaveReverse>
                            <div key="a" className="code-box-shape queue-anim-demo">
                                <span>Delicate </span>aircraft physical modeling,
                            </div>
                            <div key="a" className="code-box-shape queue-anim-demo">
                                <span>Realistic </span>physical effects.
                            </div>
                        </QueueAnim>
                    </Col>
                </OverPack>
                <OverPack className="text-content">
                    <Col xs={24} sm={24} md={12} lg={12} xl={24}>
                        <QueueAnim key="queue" leaveReverse>
                            <div key="b" className="code-box-shape queue-anim-demo">
                                fully autonomous
                            </div>
                            <div key="b" className="code-box-shape queue-anim-demo">
                                flight <span>beyond line-of-sight </span>range.
                            </div>
                        </QueueAnim>
                    </Col>
                </OverPack>
                <OverPack className="text-content">
                    <Col xs={24} sm={24} md={12} lg={12} xl={24}>
                        <QueueAnim key="queue" leaveReverse>
                            <div key="c" className="code-box-shape queue-anim-demo">
                                <span>Powerful </span>flight control algorithm,
                            </div>
                            <div key="c" className="code-box-shape queue-anim-demo">
                                Perfectly <span>suitable </span>for different models.
                            </div>
                        </QueueAnim>
                    </Col>
                </OverPack>
                <OverPack className="text-content">
                    <Col xs={24} sm={24} md={12} lg={12} xl={24}>
                        <QueueAnim key="queue" leaveReverse>
                            <div key="d" className="code-box-shape queue-anim-demo">
                                <span>Fully autonomous </span>take-off and landing,
                            </div>
                            <div key="d" className="code-box-shape queue-anim-demo">
                                mission route flight,
                            </div>
                            <div key="d" className="code-box-shape queue-anim-demo">
                                emergency protection,etc. Detailed flight environment <span>simulation.</span>
                            </div>
                        </QueueAnim>
                    </Col>
                </OverPack>
                <OverPack className="text-content">
                    <Col xs={24} sm={24} md={12} lg={12} xl={24}>
                        <QueueAnim key="queue" leaveReverse>
                            <div key="e" className="code-box-shape queue-anim-demo">
                                <span>Simple </span>equipment installation and use,<br/>
                            </div>
                            <div key="e" className="code-box-shape queue-anim-demo">
                                Quickly experience the <span>fun </span> of flying.
                            </div>
                        </QueueAnim>
                    </Col>
                </OverPack>
                <OverPack className="text-content">
                    <Col xs={24} sm={24} md={12} lg={24} xl={24}>
                        <TweenOne key="0" animation={{opacity: 1}}
                                  className="simulator-img"
                                  style={{opacity: 0, marginBottom: 10}}
                        />
                    </Col>
                </OverPack>
            </div>
        ): (
            <div>
                <OverPack className="text-content">
                    <Col xs={24} sm={24} md={12} lg={12} xl={24}>
                        <QueueAnim key="queue" leaveReverse>
                            <div key="a" className="code-box-shape queue-anim-demo">
                                <span>Delicate </span>aircraft physical modeling,
                            </div>
                            <div key="a" className="code-box-shape queue-anim-demo">
                                <span>Realistic </span>physical effects.
                            </div>
                        </QueueAnim>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={24}>
                        <QueueAnim key="queue" leaveReverse>
                            <div key="b" className="code-box-shape queue-anim-demo">
                                fully autonomous
                            </div>
                            <div key="b" className="code-box-shape queue-anim-demo">
                                flight <span>beyond line-of-sight </span>range.
                            </div>
                        </QueueAnim>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={24}>
                        <QueueAnim key="queue" leaveReverse>
                            <div key="c" className="code-box-shape queue-anim-demo">
                                <span>Powerful </span>flight control algorithm,
                            </div>
                            <div key="c" className="code-box-shape queue-anim-demo">
                                Perfectly <span>suitable </span>for different models.
                            </div>
                        </QueueAnim>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={24}>
                        <QueueAnim key="queue" leaveReverse>
                            <div key="d" className="code-box-shape queue-anim-demo">
                                <span>Fully autonomous </span>take-off and landing,
                            </div>
                            <div key="d" className="code-box-shape queue-anim-demo">
                                mission route flight,
                            </div>
                            <div key="d" className="code-box-shape queue-anim-demo">
                                emergency protection,etc. Detailed flight environment <span>simulation.</span>
                            </div>
                        </QueueAnim>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={24}>
                        <QueueAnim key="queue" leaveReverse>
                            <div key="e" className="code-box-shape queue-anim-demo">
                                <span>Simple </span>equipment installation and use,<br />
                            </div>
                            <div key="e" className="code-box-shape queue-anim-demo">
                                Quickly experience the <span>fun </span> of flying.
                            </div>
                        </QueueAnim>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={24} xl={24}>
                        <TweenOne key="0" animation={{ opacity: 1 }}
                            className="simulator-img"
                            style={{ opacity: 0, marginBottom: 10 }}
                        />
                    </Col>
                </OverPack>
            </div>
        )
    }
}
