import React from 'react';
import 'rc-texty/assets/index.css';
import './index.less'
import ScrollOverPack from "rc-scroll-anim/lib/ScrollOverPack";
import QueueAnim from "rc-queue-anim";
import TweenOne from "rc-tween-one";
import Texty from "rc-texty";
import {Carousel, Col, Image, Row} from 'antd';
import {Link} from "react-router-dom";
import {RightOutlined} from "@ant-design/icons";
import { enquireScreen } from 'enquire-js';

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});
export default class groundBanner extends React.Component {
    state = {}
    getEnter = (e) => {
        const t = {
            opacity: 0,
            scale: 0.8,
            y: '-100%',
        };
        if (e.index >= 2 && e.index <= 6) {
            return {...t, y: '-30%', duration: 150};
        }
        return t;
    }
    geInterval = (e) => {
        switch (e.index) {
            case 0:
                return 0;
            case 1:
                return 150;
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
                return 150 + 450 + (e.index - 2) * 10;
            default:
                return 150 + 450 + (e.index - 6) * 150;
        }
    }

    render() {
        function onChange(a, b, c) {
            console.log(a, b, c);
        }

        const contentStyle = {
            height: '500px',
            color: '#fff',
            lineHeight: '160px',
            textAlign: 'center',
        };
        return (
            <ScrollOverPack id="craft" className="banner-info">
                <QueueAnim
                    className="text-wrapper-top"
                    key="left"
                    duration={1450}
                    type="bottom"
                    leaveReverse
                >
                    {(
                        <div className="combined">
                            <div className="combined-shape">
                                <div className="shape-left">
                                    <TweenOne
                                        animation={[
                                            {x: 158, type: 'from', ease: 'easeInOutQuint', duration: 600},
                                            {x: -158, ease: 'easeInOutQuart', duration: 450, delay: -150},
                                        ]}
                                    />
                                </div>
                                <div className="shape-right">
                                    <TweenOne
                                        animation={[
                                            {x: -158, type: 'from', ease: 'easeInOutQuint', duration: 600},
                                            {x: 158, ease: 'easeInOutQuart', duration: 450, delay: -150},
                                        ]}
                                    />
                                </div>
                            </div>
                            <Texty
                                className="title"
                                type="mask-top"
                                enter={this.getEnter}
                                interval={this.geInterval}
                                component={TweenOne}
                                componentProps={{
                                    animation: [
                                        {x: 130, type: 'set'},
                                        {x: 100, delay: 500, duration: 450},
                                        {
                                            ease: 'easeOutQuart',
                                            duration: 300,
                                            x: 0,
                                        },
                                        {
                                            letterSpacing: 0,
                                            delay: -300,
                                            scale: 0.9,
                                            ease: 'easeInOutQuint',
                                            duration: 1000,
                                        },
                                        {scale: 1, width: '100%', ease: 'easeInOutQuint'},
                                    ],
                                }}
                            >
                                Ground
                            </Texty>
                        </div>
                    )}
                </QueueAnim>
                <Carousel afterChange={onChange} autoplay>
                    <div>
                        <h3 style={contentStyle}>
                            <Row justify="start" align="middle">
                                <Col className={"banner-left"} xs={20} sm={16} md={12} lg={12} xl={10}>
                                    <QueueAnim
                                        className="text-wrapper left-text"
                                        key="text"
                                        duration={450}
                                        type="bottom"
                                        leaveReverse
                                    >
                                        <p key="p" style={{fontSize: "2rem", color: "rgb(0,138,214)"}}>
                                            Datalink
                                        </p>
                                        <div key="button" className={'more-div'}>
                                            <Link to={"/datalink"} style={{color: "#fff"}}>
                                                <span>Learn more</span>
                                            </Link>
                                            <RightOutlined/>
                                        </div>
                                    </QueueAnim>
                                </Col>
                                <Col className={"page-box"} xs={20} sm={16} md={12} lg={12} xl={14}>
                                    <Image width={600} height={isMobile ? 370 : 600} preview={false} className={'banner-image1'}
                                           src="http://qiniu.metridynamic.com/image%2Fhome%2FDatalink.png"
                                    />
                                </Col>
                            </Row>
                        </h3>
                    </div>
                    <div>
                        <h3 style={contentStyle}>
                            <Row justify="center">
                                <Col className={"banner-left"} xs={20} sm={16} md={12} lg={11} xl={10}>
                                    <QueueAnim
                                        className="text-wrapper left-text"
                                        key="text"
                                        duration={450}
                                        type="bottom"
                                        leaveReverse
                                    >
                                        <p key="p" style={{fontSize: "2rem", color: "rgb(0,138,214)"}}>
                                            Ground Control Station
                                        </p>
                                        <div key="button" className={'more-div'}>
                                            <Link to={"/GCS"} style={{color: "#fff"}}>
                                                <span>Learn more</span>
                                            </Link>
                                            <RightOutlined/>
                                        </div>
                                    </QueueAnim>
                                </Col>
                                <Col className={"page-box"} xs={20} sm={16} md={12} lg={11} xl={14}>
                                    <Image width={600} height={isMobile ? 335 : 600} preview={false} className={'banner-image1'}
                                           src="http://qiniu.metridynamic.com/image%2Fhome%2FGround%20Control%20Station.png"
                                    />
                                </Col>
                            </Row>
                        </h3>
                    </div>
                    <div>
                        <h3 style={contentStyle}>
                            <Row justify="center">
                                <Col className={"banner-left"} xs={20} sm={16} md={12} lg={11} xl={10}>
                                    <QueueAnim
                                        className="text-wrapper left-text"
                                        key="text"
                                        duration={450}
                                        type="bottom"
                                        leaveReverse
                                    >
                                        <p key="p" style={{fontSize: "2rem", color: "rgb(0,138,214)"}}>
                                            Simulator
                                        </p>
                                        <div key="button" className={'more-div'}>
                                            <Link to={"/simulator"} style={{color: "#fff"}}>
                                                <span>Learn more</span>
                                            </Link>
                                            <RightOutlined/>
                                        </div>
                                    </QueueAnim>
                                </Col>
                                <Col className={"page-box"} xs={20} sm={16} md={12} lg={11} xl={14}>
                                    <Image width={600} height={isMobile ? 320 : 600} preview={false} className={'banner-image1'}
                                           src="http://qiniu.metridynamic.com/image%2Fhome%2FSimulator.png"
                                    />
                                </Col>
                            </Row>
                        </h3>
                    </div>
                </Carousel>
            </ScrollOverPack>
        );
    }
}
