import React from "react";
import './index.less';
import {Page1, Page2, Page3, Page4} from "./components/content/index";
import PayloadVideo from "./components/video";
import {enquireScreen} from 'enquire-js';

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

export default class Payload extends React.Component {
    constructor(porps) {
        super(porps);
        this.state = {
            isMobile,
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className='payload-content'>
                <PayloadVideo/>
                <Page1 key="page1" isMobile={this.state.isMobile}/>
                {
                    this.state.isMobile
                        ?
                        <div className="flying-bottom-line" style={{
                            width: "83%",
                            height: "0.5px",
                            background: "rgba(254,254,254,0.5)",
                            margin: "0 auto"
                        }}></div>
                        :
                        ""
                }
                <Page2 key="page2"/>
                {
                    this.state.isMobile
                        ?
                        <div className="flying-bottom-line" style={{
                            width: "83%",
                            height: "0.5px",
                            background: "rgba(254,254,254,0.5)",
                            margin: "0 auto"
                        }}></div>
                        :
                        ""
                }
                <Page3 key="page3" isMobile={this.state.isMobile}/>
                {
                    this.state.isMobile
                        ?
                        <div className="flying-bottom-line" style={{
                            width: "83%",
                            height: "0.5px",
                            background: "rgba(254,254,254,0.5)",
                            margin: "0 auto"
                        }}></div>
                        :
                        ""
                }
                <Page4 key="page4"/>
            </div>
        )
    }
}
