import React from "react";
import QueueAnim from 'rc-queue-anim';
import './index.less'
import { Card, Row, Col, Collapse, Carousel } from 'antd';

const { Panel } = Collapse;
const { Meta } = Card;

export default class MobileGimbalContent1 extends React.Component {
    callback(key) {
        console.log(key);
    }

    render() {
        const cardStyle = {
            width: 220,
            padding: 8,
            margin: "0 auto",
            background: "transparent",
            border: "none"
        }
        return (
            <div className='mobile-gimbal-content1'>
                <Carousel>
                    <div style={{height:320}}>
                        <Card
                            hoverable
                            cover={<img alt="example" src="http://qiniu.metridynamic.com/image/new-official-en/Gimbal/20220407-143540.png" />}
                            style={cardStyle}
                        >
                            <Collapse onChange={this.callback} ghost expandIconPosition={"right"}>
                                <Panel header="GS3W" key="1">
                                    <p>Three-axis stabilization</p>
                                    <p>30x zoom HD EO</p>
                                    <p>Weak light fusion display</p>
                                    <p>Thermal imaging</p>
                                    <p>Intelligent tracking</p>
                                </Panel>
                            </Collapse>
                        </Card>
                    </div>
                    <div>
                        <Card
                            hoverable
                            cover={<img alt="example" src="http://qiniu.metridynamic.com/image/new-official-en/Gimbal/20220407-143533.png" />}
                            style={cardStyle}
                        >
                            <Collapse onChange={this.callback} ghost expandIconPosition={"right"}>
                                <Panel header="GS5T" key="1">
                                    <p>Three-axis stabilization</p>
                                    <p>30x zoom HD EO</p>
                                    <p>Weak light fusion display</p>
                                    <p>Thermal imaging</p>
                                    <p>Intelligent tracking</p>
                                    <p>Laser range finder</p>
                                    <p>Thermal FOVx2</p>
                                </Panel>
                            </Collapse>
                        </Card>
                    </div>
                    <div>
                        <Card
                            hoverable
                            cover={<img alt="example" src="http://qiniu.metridynamic.com/image/new-official-en/Gimbal/20220407-143545.png" />}
                            style={cardStyle}
                        >
                            <Collapse onChange={this.callback} ghost expandIconPosition={"right"}>
                                <Panel header="GS4L" key="1">
                                    <p>Three-axis stabilization</p>
                                    <p>30x zoom HD EO</p>
                                    <p>Weak light fusion display</p>
                                    <p>Thermal imaging</p>
                                    <p>Intelligent tracking</p>
                                    <p>Laser range finder</p>
                                </Panel>
                            </Collapse>
                        </Card>
                    </div>
                </Carousel>
            </div>
        )

    }
}
