import React from "react";
import QueueAnim from 'rc-queue-anim';
import './style.less'
import { Tabs, Radio, Space } from 'antd';
const { TabPane } = Tabs;

export default class LidarVideoContent extends React.Component {

    render() {
        return (
            <div className="Lidar-video-container">
                <h1>3D liar pointcloud mode processed from RiProcess</h1>
                <div className={"content"}>
                            <div className="video-container video">
                                <video
                                 x5-playsinline="true"
                                 playsinline="true" 
                                 webkit-playsinline="true"
                                src="http://qiniu.metridynamic.com/video%2F%E6%BF%80%E5%85%89%E9%9B%B7%E8%BE%BE%E9%A1%B5%E9%9D%A2%E8%A7%86%E9%A2%91%E4%BF%AE%E6%94%B9_x264.mp4" autoPlay loop muted>
                                </video>
                            </div>
                </div>
            </div>
        )

    }
}
