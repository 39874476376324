import React from "react";
import QueueAnim from 'rc-queue-anim';
import './index.less'
import { Card, Row, Col, Collapse } from 'antd';
import TweenOne from 'rc-tween-one';
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';

const { Panel } = Collapse;
const { Meta } = Card;

export default class OrthographicContent1 extends React.Component {


    render() {
        const cardStyle = {
            width: 420,
            padding: 8,
            margin: "0 auto",
            border: "none"
        }
        const style = { background: '#0092ff', padding: '8px 0' };
        return (
            <ScrollOverPack className='content-wrapper page Orthographic-content1'>
                <Row justify="center">
                    <Col className={"page-box"} xs={20} sm={16} md={12} lg={11} xl={11}>
                        <h1 className="m6">M6</h1>
                        <TweenOne
                            key="image"
                            className="image-wrapper Orthographic-content-image1"
                            animation={{ x: 0, opacity: 1, ease: 'easeOutQuad' }}
                            style={{ transform: 'translateX(-100px)', opacity: 0 }}
                        />
                    </Col>
                    <Col className={"page-box"} xs={20} sm={16} md={12} lg={11} xl={11}>
                        <QueueAnim
                            key="QueueAnim"
                            type={['bottom', 'top']}
                            delay={200}
                            className='text-wrapper Orthographic-text'
                        >
                            <Row gutter={[16, 24]}>
                            <Col className="gutter-row" span={12}>
                                    <img src="http://qiniu.metridynamic.com/image/new-official-en/orthographic/%E5%85%A8%E7%94%BB%E5%B9%85%E8%8B%B1%E6%96%87%E7%89%88%E6%9C%AC.png" alt={""} />
                                    <p>Full Frame</p>
                                </Col>
                                <Col className="gutter-row" span={12}>
                                    <img src="http://qiniu.metridynamic.com/image/new-official-en/orthographic/%E5%AE%9E%E4%BA%8B%E5%8F%8D%E9%A6%88.png" alt={""} />
                                    <p>Real-time feedback on photo status</p>
                                </Col>
                                <Col className="gutter-row" span={12}>
                                    <img src="http://qiniu.metridynamic.com/image/new-official-en/orthographic/GPS%E4%BF%A1%E6%81%AF%E5%8F%AA%E8%AF%BB.png" alt={""} />
                                    <p>Real-time GPS info</p>
                                </Col>
                                
                                <Col className="gutter-row" span={12}>
                                    <img src="http://qiniu.metridynamic.com/image/new-official-en/orthographic/timesync%E6%8A%80%E6%9C%AF.png" alt={""} />
                                    <p>Timesync tecnology</p>
                                </Col>
                               
                                <Col className="gutter-row" span={12}>
                                    <img src="http://qiniu.metridynamic.com/image/new-official-en/orthographic/6100%E4%B8%87%E5%83%8F%E7%B4%A0%E8%8B%B1%E6%96%87.png" alt={""} />
                                    <p>61Million Pixels</p>
                                </Col>
                                
                                <Col className="gutter-row" span={12}>
                                    <img src="http://qiniu.metridynamic.com/image/new-official-en/orthographic/%E9%9B%86%E6%88%90%E5%BA%A6%E9%AB%98.png" alt={""} />
                                    <p>Hight integration</p>
                                </Col>
                            </Row>
                        </QueueAnim>
                    </Col>
                </Row>


            </ScrollOverPack>
        )

    }
}
