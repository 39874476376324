import React from 'react';
import PropTypes from 'prop-types';
import TweenOne from 'rc-tween-one';
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import {  Row, Col } from 'antd';
import QueueAnim from 'rc-queue-anim';
export default function Page1({ isMobile }) {
  return (
    <ScrollOverPack id="page1" className="content-wrapper page">
      <Row justify='center'>
        <Col className='page-box' xs={20} sm={16} md={12} lg={11} xl={11}>
          <TweenOne
            key="image"
            className="image6 image-wrapper"
            animation={{ x: 0, opacity: 1, ease: 'easeOutQuad' }}
            style={{ transform: 'translateX(-100px)', opacity: 0 }}
          />
        </Col>
        <Col className='page-box' xs={20} sm={16} md={12} lg={11} xl={11}>
          <QueueAnim
            type={isMobile ? 'bottom' : 'right'}
            className="text-wrapper"
            key="text"
            leaveReverse
          >
            <h2 key="h2">LMLS shutter, 500,000+ shutter life!</h2>
            <p key="p">
            Independent design without distortion shutter (MLS shutter), its blade using the current extremely tough MJ60 level carbon fiber material, to ensure the realization of more than 500,000 times the high-speed impact; and each shutter blade using independent motor drive mode, the disadvantage of this way is that the shutter volume and weight increased significantly, but can greatly reduce the wear error, after several fatigue tests, in 500,000 times the work After a number of fatigue tests, only 3% or less time error, this level has reached the industry's top level; in the actual operation process, northern customers will find that in the winter lower temperature scenes, the camera may not take pictures, this is because the shutter blade water vapor icing jam, resulting in the camera can not work properly, we have a blade coated with a coating to prevent condensation, can effectively prevent the shutter failure caused by low temperature; RuiPo MLS shutter Not only can ensure that there is no motion distortion under high-speed movement, while ensuring high reliability and high life. Bringing the ultimate experience to customers while completing aerial photography tasks;</p>
          </QueueAnim>
        </Col>
      </Row>
    </ScrollOverPack>
  );
}
Page1.propTypes = {
  isMobile: PropTypes.bool,
};
