import React from 'react';
import PropTypes from 'prop-types';
import TweenOne from 'rc-tween-one';
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { RightOutlined } from '@ant-design/icons';
import { Button, Row, Col } from 'antd';
import QueueAnim from 'rc-queue-anim';
import { Link } from 'react-router-dom'
export default function Page1({ isMobile }) {
  return (
    <ScrollOverPack className="home-content-wrapper page">
      <Row justify='center'>
        <Col className={"page-box"} xs={20} sm={16} md={12} lg={11} xl={11}>
          <TweenOne
            key="image"
            className="ground1 image-wrapper"
            animation={{ x: 0, opacity: 1, ease: 'easeOutQuad' }}
            style={{ transform: 'translateX(-100px)', opacity: 0 }}
          />
        </Col>
        <Col className={"page-box"} xs={20} sm={16} md={12} lg={11} xl={11}>
          <QueueAnim
            type={isMobile ? 'bottom' : 'right'}
            className="text-wrapper"
            key="text"
            leaveReverse
          >
            <h2 key="h2">"Dual Screen Display,<br/> Easy to Control"</h2>
            <p key="p" style={{ maxWidth: 260 }}>Ground Control Station</p>
            <div key="button">
              <a>
                <Link to={"/GCS"}>
                <span>Learn more</span>
                  <RightOutlined />
                </Link>
              </a>
            </div>
          </QueueAnim>
        </Col>
      </Row>


    </ScrollOverPack>
  );
}
Page1.propTypes = {
  isMobile: PropTypes.bool,
};
