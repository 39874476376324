import React from "react";
import QueueAnim from 'rc-queue-anim';
import './style.less'
import { Tabs, Radio, Space } from 'antd';
const { TabPane } = Tabs;

export default class OrthographicVideoContent extends React.Component {

    render() {
        return (
            <div className="Orthographic-video-container">
                <h1>DOM image generated from M6 Orthographic Camera</h1>
                <div className={"content"}>
                    <div className="video-container video">
                        <video
                         x5-playsinline="true"
                         playsinline="true" 
                         webkit-playsinline="true"
                            src="http://qiniu.metridynamic.com/video%2F%E6%AD%A3%E5%B0%84%E7%9B%B8%E6%9C%BA%E8%A7%86%E9%A2%91%E5%B1%95%E7%A4%BA.mp4" autoPlay loop muted>
                        </video>
                    </div>
                </div>
            </div>
        )

    }
}
