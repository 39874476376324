import React from "react";
import './style.less'
import {Tabs} from 'antd';

const {TabPane} = Tabs;

export default class GimbalVideoContent extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="gimbal-video-container">
                <div className={"content"}>
                    <Tabs tabPosition={this.props.isMobile ? "top" : "left"}>
                        <TabPane tab="30x zoom HD EO" key="1">
                            <div className="video-container video">
                                <video src="http://qiniu.metridynamic.com/video/new%20video/20220424-134341.mp4"
                                       autoPlay loop muted>
                                </video>
                            </div>
                        </TabPane>
                        <TabPane tab="Weak light fusion display" key="2">
                            <div className="video-container video">
                                <video src="http://qiniu.metridynamic.com/video%2F%E5%90%8A%E8%88%B1%E7%83%AD%E6%88%90%E5%83%8F%E5%92%8C%E6%97%A5%E5%85%89%E7%9B%B8%E6%9C%BA%E8%9E%8D%E5%90%88%E8%8B%B1%E6%96%87%E7%89%88.mp4" autoPlay loop muted>
                                </video>
                            </div>
                        </TabPane>
                        <TabPane tab="Thermal imaging" key="3">
                            <div className="video-container video">
                                <video src="http://qiniu.metridynamic.com/video/thermalImagery.mp4" autoPlay loop muted>
                                </video>
                            </div>
                        </TabPane>
                        <TabPane tab="Intelligent tracking" key="4">
                            <div className="video-container video">
                                <video src="http://qiniu.metridynamic.com/video/new%20video/20220424-134338.mp4"
                                       autoPlay loop muted>
                                </video>
                            </div>
                        </TabPane>
                        <TabPane tab="Gimbal FOV and target location display" key="5">
                            <div className="video-container video">
                                <video
                                    src="http://qiniu.metridynamic.com/video%2Fnew%20video%2Fpayload%2F%E9%A3%9E%E4%B9%A620220512-094403.mp4"
                                    autoPlay loop muted>
                                </video>
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        )

    }
}
