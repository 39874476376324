import React from "react";
import QueueAnim from 'rc-queue-anim';
import './index.less'
import {Col, Row, Table} from 'antd';
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';

export default class OrthographicContent2 extends React.Component {


    render() {
        const columns = [
            {
                title: 'Product parameters',
                dataIndex: 'name',
                key: 'name',
                align: 'center',
                width: 650
            },
            {
                title: '',
                dataIndex: 'param',
                key: 'param',
                align: 'center',
                width: 650
            },
        ];

        const data = [
            {
                key: '1',
                name: 'Total Pixels',
                param: '102 million',
            },
            {
                key: '2',
                name: 'Camera Memory',
                param: '512GB * 2',
            },
            {
                key: '3',
                name: 'Exposure time interval',
                param: '0.5 s',
            },
            {
                key: '4',
                name: 'Data copy mode',
                param: 'external storage high-speed download',
            },
            {
                key: '5',
                name: 'Shutter Speed',
                param: '1/100-1/2000s',
            },
            {
                key: '6',
                name: 'Control parameter',
                param: 'Remote Control/serial port/Bluetooth',
            },
            {
                key: '7',
                name: 'Power Interface',
                param: 'Skyport/DC/custom',
            },
            {
                key: '9',
                name: 'Supply voltage',
                param: 'DC (12V-27V) ',
            },
            {
                key: '10',
                name: 'Data Processing',
                param: 'Skyscanner (advanced) ',
            },
            {
                key: '11',
                name: 'Route Planning',
                param: 'Rainpoo Route Assistant',
            },
        ];
        return (
            <ScrollOverPack className='content-wrapper page Orthographic-content2'>
                <Row justify="center">
                    <Col className={"page-box"} span="24">
                        <QueueAnim
                            key="QueueAnim"
                            type={['bottom', 'top']}
                            delay={200}
                            className='text-wrapper Orthographic-text'
                        >
                            <Table columns={columns} dataSource={data} pagination={false}/>
                        </QueueAnim>
                    </Col>
                </Row>


            </ScrollOverPack>
        )

    }
}
