import React from 'react';
import './content5.less';
import { Row, Col } from 'antd';
import { TweenOneGroup } from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';

import { getChildrenToRender } from '../../utils';
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';
class Content5 extends React.PureComponent {
    state = {
        show: true
    };
  getChildrenToRender = (data) =>
    data.map((item) => {
      return (
          <div key={item.name} {...item}>
              {/* <a href="#"> */}
                  <div className="item-logo">
                      {/*<img src={item.children.img.children} alt=""/>*/}
                  </div>
                  <div className="item-title">{item.children.content.children}</div>
                  {/* <div className="item-text">
                      <p>母语级翻译</p>
                      <p>提供“海外部署”</p>
                      <p>打通海外社交媒体</p>
                      <p>海外商机汇集</p>
                  </div> */}
              {/* </a> */}
          </div>
      );
    });
    getEnter = (e) => {
        switch (e.index) {
            case 0:
                return {
                    rotate: 90,
                    opacity: 0,
                    y: -60,
                };
            case 10:
            case 1:
                return {
                    y: -60,
                    x: -10,
                    opacity: 0,
                };
            case 9:
            case 2:
                return {
                    y: -60,
                    x: 20,
                    opacity: 0,
                };
            case 3:
                return {
                    y: 60,
                    opacity: 0,
                };
            case 8:
            case 4:
                return {
                    x: 30,
                    opacity: 0,
                };
            case 5:
                return {
                    enter: [
                        {
                            scale: 2,
                            opacity: 0,
                            type: 'set',
                        },
                        { scale: 1.2, opacity: 1, duration: 300 },
                        { scale: 0.9, duration: 200 },
                        { scale: 1.05, duration: 150 },
                        { scale: 1, duration: 100 },
                    ],
                    leave: {
                        opacity: 0, scale: 0,
                    },
                };
            case 6:
                return {
                    scale: 0.8,
                    x: 30,
                    y: -10,
                    opacity: 0,
                };
            case 7:
                return {
                    scale: 0.8,
                    x: 30,
                    y: 10,
                    opacity: 0,
                };
            default:
                return {
                    opacity: 0,
                };
        }
    }
  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    const childrenToRender = this.getChildrenToRender(
      dataSource.block.children
    );
    return (
      <section {...props} {...dataSource.wrapper}>
        <div {...dataSource.page}>
          <div key="title" {...dataSource.titleWrapper}>
              <Texty enter={this.getEnter} leave={this.getEnter} style={{color:"#fff"}}>{this.state.show && 'VARIOUS APPLICATIONS'}</Texty>
          </div>
            <div className="trade-content">
                <div className="trade-black">
                    <div className="trade-box">
                        <div className="trade-box-list">
                            {childrenToRender}
                        </div>
                    </div>
                </div>
            </div>
            {/* <div style={{'display': 'flex','justify-content': 'space-evenly'}}>
                <div>
                    <iframe ref="iframe" scrolling="yes" frameBorder="0"
                            style={{ width:"400px",height:"600px", border: 'none', overflow: 'hidden'}}
                            src={"https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FMetriDynamic&tabs=timeline&width=400&height=600&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"}
                    />
                </div>
                <div>
                    <iframe ref="iframe" scrolling="yes" frameBorder="0"
                        style={{ width: '400px', height: '575px', border: 'none', overflow: 'hidden' }}
                            src={"https://www.linkedin.com/embed/feed/update/urn:li:share:6935584167376150530"}
                    />
                </div>
            </div> */}
        </div>
      </section>
    );
  }
}

export default Content5;
