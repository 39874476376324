import React from "react";
import './style.less';
import { Image, Row, Col } from "antd";

export default class ServiceContent extends React.Component {
    render() {
        return (
            <div className={'service-content'}>
                <div className="block">
                    <h1>Intimate technology and after-sales service</h1>
                    <p>MetriDynamic is deemed to escort the entire life cycle of the product!</p>
                    <h4>Solving the problems on the user site <br /> is our continuous goal</h4>
                    <Row justify="center" className="block-desc">
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <span>8</span> hours remote solution
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <span>48</span> hours on-spot
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}
