import React from "react";
import QueueAnim from 'rc-queue-anim';
import './index.less'
import {Card, Row, Col, Collapse,Carousel} from 'antd';

const {Panel} = Collapse;
const {Meta} = Card;

export default class MobileobliqueContent1 extends React.Component {


    render() {
        const cardStyle = {
            width: 220,
            padding: 8,
            margin: "0 auto",
            border:"none",
            background:"transparent"
        }
        return (
            <div className='mobile-oblique-content1'>
                 <Carousel autoplay dots={false}>
                    <div>
                        <Card
                            hoverable
                            cover={<img style={{width:220}} alt="example" src="http://qiniu.metridynamic.com/image/new-official-en/oblique/DG3M.png"/>}
                            style={cardStyle}
                        >
                                <Meta title="DG3M" description="" />
                        </Card>
                    </div>
                    <div>
                    <Card
                        hoverable
                        cover={<img alt="example" src="http://qiniu.metridynamic.com/image/new-official-en/oblique/DG4M-%EF%BC%882%EF%BC%89.png"/>}
                        style={cardStyle}
                    >
                        <Meta title="DG4M" description="" />
                    </Card>
                    </div>
                </Carousel>
            </div>
        )

    }
}
