import React from 'react';
import TweenOne from 'rc-tween-one';
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { RightOutlined } from '@ant-design/icons';
import { Button, Row, Col } from 'antd';
import QueueAnim from 'rc-queue-anim';
import { Link } from "react-router-dom";

export default function Page4() {
    return (
        <ScrollOverPack
            className="home-content-wrapper page"
        >
            <Row justify='center'>
                <Col className={"page-box"} xs={20} sm={16} md={12} lg={11} xl={11}>
                    <QueueAnim
                        className="text-wrapper left-text"
                        key="text"
                        duration={450}
                        type="bottom"
                        leaveReverse
                        style={{top:"10%"}}
                    >
                        <h2 key="h2">"Real simulation with <br/>game-like experience"</h2>
                        <p key="p" style={{ maxWidth: 260 }}>Simulator</p>
                        <div key="button">
                            <Link to="/simulator">
                            <span>Learn more</span>
                                    <RightOutlined />
                            </Link>
                        </div>
                    </QueueAnim>
                </Col>
                <Col className={"page-box"} xs={20} sm={16} md={12} lg={11} xl={11}>
                    <TweenOne
                        key="image"
                        className="ground4 image-wrapper"
                        animation={{ x: 0, opacity: 1, ease: 'easeOutQuad' }}
                        style={{ transform: 'translateX(100px)', opacity: 0 }}
                    />
                </Col>
            </Row>


        </ScrollOverPack>
    );
}
