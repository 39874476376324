import React from "react";
import Content1 from "./components/content1";
import Content2 from "./components/content2";
import Content3 from "./components/content3";
import Content4 from "./components/content4";
import Content5 from "./components/content5";
import Texty from 'rc-texty';

import { enquireScreen } from 'enquire-js';
import './index.less';
let isMobile;
enquireScreen((b) => {
    isMobile = b;
});
export default class LatestProducts extends React.Component {
    // eslint-disable-next-line no-useless-constructor
    constructor(porps) {
        super(porps);
        this.state = {
            isMobile,
            percentage: 0,
            textShow: true
        };
    }
    getEnter = (e) => {
        switch (e.index) {
            case 0:
                return {
                    rotate: 90,
                    opacity: 0,
                    y: -60,
                };
            case 10:
            case 1:
                return {
                    y: -60,
                    x: -10,
                    opacity: 0,
                };
            case 9:
            case 2:
                return {
                    y: -60,
                    x: 20,
                    opacity: 0,
                };
            case 3:
                return {
                    y: 60,
                    opacity: 0,
                };
            case 8:
            case 4:
                return {
                    x: 30,
                    opacity: 0,
                };
            case 5:
                return {
                    enter: [
                        {
                            scale: 2,
                            opacity: 0,
                            type: 'set',
                        },
                        { scale: 1.2, opacity: 1, duration: 300 },
                        { scale: 0.9, duration: 200 },
                        { scale: 1.05, duration: 150 },
                        { scale: 1, duration: 100 },
                    ],
                    leave: {
                        opacity: 0, scale: 0,
                    },
                };
            case 6:
                return {
                    scale: 0.8,
                    x: 30,
                    y: -10,
                    opacity: 0,
                };
            case 7:
                return {
                    scale: 0.8,
                    x: 30,
                    y: 10,
                    opacity: 0,
                };
            default:
                return {
                    opacity: 0,
                };
        }
    }
    render() {
        return (
            <div>
                <div className="text-wrapper-video">
                    <Texty enter={this.getEnter} leave={this.getEnter} delay={300}>{this.state.textShow && 'Extreme Imagery'}</Texty>
                </div>
                <div className='flying-video'>
                    <img src="https://qiniu.metridynamic.com/image/latestProducts/20230413-095728.png" style={this.state.isMobile?{ height: '27vh' }:{ height: '100vh' }} alt={""} />
                </div>
                <Content1></Content1>
                <Content2></Content2>
                <div className='flying-platform'>
                    <Content3 key="page1" isMobile={this.state.isMobile}></Content3>
                    <Content4 key="page1" isMobile={this.state.isMobile}></Content4>
                    <Content5 key="page1" isMobile={this.state.isMobile}></Content5>
                </div>
            </div>
        );
    }
}