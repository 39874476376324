import React from "react";
import './style.less'
import { Row, Col, Image } from 'antd'
export default class HowToBuy extends React.Component {
    render() {
        const gutter = {
            xs: 8, sm: 16, md: 24, lg: 32
        }
        const list = [
            {
                key: 1,
                bgSrc: "http://qiniu.metridynamic.com/image/new-official-en/insurance/1.png",
                desc: "Contact our sales to get insurance plan"
            },
            {
                key: 2,
                bgSrc: "http://qiniu.metridynamic.com/image/new-official-en/insurance/2.png",
                desc: "Provide insurance information confirm insurance and pay"
            },
            {
                key: 3,
                bgSrc: "http://qiniu.metridynamic.com/image/new-official-en/insurance/3.png",
                desc: "Insurance company reviews and enters the system"
            },
            {
                key: 4,
                bgSrc: "http://qiniu.metridynamic.com/image/new-official-en/insurance/4.png",
                desc: "Generate and take effect"
            },
            {
                key: 5,
                bgSrc: "http://qiniu.metridynamic.com/image/new-official-en/insurance/5.png",
                desc: "The insurance completed and the voucher obtained"
            },
        ]
        return (
            <div className={'how-to-buy'}>
                <h1>How to buy?</h1>
                <div className={'buy-process'}>
                    <Row justify="center">
                        {
                            list.map(item => {
                                return <Col className="col-item" xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                                    <div className="item-box">
                                        <div className="num">{item.key}</div>
                                        <div className="icon-img">
                                            <Image preview={false} src={item.bgSrc} />
                                        </div>
                                        <p>{item.desc}</p>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </div>
            </div>
        )
    }
}
