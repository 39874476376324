import React from 'react';
import PropTypes from 'prop-types';
import TweenOne from 'rc-tween-one';
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import {Col, Row} from 'antd';
import QueueAnim from 'rc-queue-anim';
import {Link} from "react-router-dom";

export default function Page3({isMobile}) {
    return (
        <ScrollOverPack id="page3" className="content-wrapper page">
            <Row justify='center'>
                <Col className='page-box' xs={20} sm={16} md={12} lg={11} xl={11}>
                    <TweenOne
                        key="image"
                        className="image3 image-wrapper"
                        animation={{x: 0, opacity: 1, ease: 'easeOutQuad'}}
                        style={{transform: 'translateX(-100px)', opacity: 0}}
                    />
                </Col>
                <Col className='page-box' xs={20} sm={16} md={12} lg={11} xl={11}>
                    <QueueAnim
                        className="text-wrapper mobile-style"
                        key="text"
                        type={isMobile ? 'bottom' : 'right'}
                        leaveReverse
                        style={{top: '40%'}}
                    >
                        <h2 key="h2">Supports Various Payloads</h2>
                        <p key="p">
                            The payload modules are redesigned in order to meet the various payload demand.
                            It is compatible with gimbal, orthographic camera,
                            oblique camera, muti-spectral camera and even lidar.
                        </p>
                        <p className='learn-more-aircraft'><Link to="/payload">Learn more</Link></p>
                    </QueueAnim>
                </Col>
            </Row>
        </ScrollOverPack>
    );
}
Page3.propTypes = {
    isMobile: PropTypes.bool,
};
