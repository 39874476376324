import React from "react";
import './style.less';
import { Row, Col, Image, Carousel } from "antd";

export default class MobileTrainingContent1 extends React.Component {
  
    render() {
        return (
            <div className={'mobile-training-content1'}>
                <div className={'content-box'}>
                    <Carousel autoplay>
                        <div className="explain">
                            <Image preview={false} height={219} style={{width:320}} src={"http://qiniu.metridynamic.com/image/training/pilot.png"}/>
                            <p className={"title"}>Professional VTOL aircraft pilot</p>
                            <p className={"content"}>
                            <div className="split-line"></div>
                                In order to meet the needs of P series
                                VTOL product test fight, delivery,
                                customer training,AOPA training,
                                etc. we have professional technclogy persornel
                                and maragers to provide systematic and hierarchical
                                professional trainning services for aircraft products and solutions</p>
                        </div>
                        <div className="explain">
                            <Image preview={false} height={219} style={{width:320}} src={"http://qiniu.metridynamic.com/image/new-official-en/training/20220419-152027.png"}/>
                            <p className={"title"}>Fully-equipped flight base</p>
                            <p className={"content"}>
                            <div className="split-line"></div>
                                The Aircraft Base for MetriDynamic is located in Qingbajiang District, Chengdu. The base covers an area of 1000 acres and is only 22 kilometers away from Chengdu. The base has converient transportation, graceful environment, completely infrastructure and many surrounding tourist attractions to meet various testing, demonstration, trainning and other needs</p>
                        </div>
                    </Carousel>
                </div>
            </div>
        )
    }
}
