import React from "react";
import QueueAnim from 'rc-queue-anim';
import './style.less'
import {Card, Row, Col, Table} from 'antd';

const {Meta} = Card;

export default class ParametersContent extends React.Component {

    render() {
        const columns = [
            {
                title: '',
                dataIndex: 'name',
                key: 'name',
                width:380,
                align:"center"
            },
            {
                title: 'miniVUX-1LR',
                key: 'mini UVX3UAV',
                dataIndex: 'UVX3UAV',
                width:380,
                align:"center"
            },
            {
                title: 'VUX-1LR²²',
                dataIndex: 'VUX1LR',
                key: 'VUX1LR',
                width:380,
                align:"center"
            },
            
            {
                title: 'VUX-120',
                dataIndex: 'UVX120',
                key: 'UVX120',
                width:380,
                align:"center"
            },
        ];

        const data = [
            {
                key: '1',
                name: 'Scanning Frequency(Hz)',
                UVX3UAV: '100k',
                VUX1LR: '1.5M',
                UVX120: '1.8M',
            },
            {
                key: '2',
                name: 'Maximum Ranging(m)',
                UVX3UAV: '500',
                VUX1LR: '1845',
                UVX120: '1400',
            },
            {
                key: '3',
                name: 'FOV',
                UVX3UAV: '360',
                VUX1LR: '360',
                UVX120: '100(+10FWD/ -10 FWD)',
            },
            {
                key: '4',
                name: 'Integrated Weight(kg)',
                UVX3UAV: '2.0',
                VUX1LR: '4.5',
                UVX120: '2.7',
            },
            {
                key: '3',
                name: 'Point Cloud Density(Pts/m2)',
                UVX3UAV: '10',
                VUX1LR: '30-80',
                UVX120: '80-300',
            },
            {
                key: '3',
                name: 'Flight Time',
                UVX3UAV: '2h30min',
                VUX1LR: '1h15min',
                UVX120: '1h45min',
            },
        ];
        return (
            <div className="Lidar-description-container">
                    <Table columns={columns} dataSource={data} pagination={false}/>
            </div>

        )

    }
}
