import React from "react";
import QueueAnim from 'rc-queue-anim';
import './style.less'
import { Card, Row, Col } from 'antd';

const { Meta } = Card;

export default class ParametersContent2 extends React.Component {

    render() {
        return (
            <div className="gcs-description-containe2r">
                <div className="description">
                    <div className='line'>
                        <Row>
                            <Col span="8">
                                <img src="http://qiniu.metridynamic.com/image/new-official-en/GCS/%E8%A7%86%E9%A2%91%E6%8E%A5%E5%8F%97%E6%98%BE%E7%A4%BA.png" alt={""} />
                                <p>The gimbal video can be accepted and displayed,
                                    including visible light and infrared thermal image</p>
                            </Col>
                            <Col span="8">
                                <img src="http://qiniu.metridynamic.com/image/new-official-en/GCS/%E9%AB%98%E4%BA%AE%E6%98%BE%E7%A4%BA%E5%B1%8F.png" alt={""} />
                                <p>10-inch bright display,
                                    IP45 waterproof level</p>
                            </Col>
                            <Col span="8">
                                <img src="http://qiniu.metridynamic.com/image/new-official-en/GCS/%E8%A7%A6%E6%8E%A7.png" alt={""} />
                                <p>Multi-touch screen, pointing lock</p>
                            </Col>
                        </Row>
                    </div>
                    <div className='line'>
                        <Row>
                            <Col span="8">
                                <img src="http://qiniu.metridynamic.com/image/new-official-en/GCS/%E6%8E%A7%E5%88%B6%E9%81%A5%E6%84%9F.png" alt={""} />
                                <p>Built-in gimbal control joystick, three-axis design,
                                    integrated rotation and zoom.</p>
                            </Col>
                            <Col span="8">
                                <img src="http://qiniu.metridynamic.com/image/new-official-en/GCS/weight.png" alt={""} />
                                <p>The weight of the whole machine is less than 2kgm
                                    and it can be operated by hand</p>
                            </Col>
                            <Col span="8">
                                <img src="http://qiniu.metridynamic.com/image/new-official-en/GCS/time.png" alt={""} />
                                <p>Up to 4 hours of battery using</p>
                            </Col>

                        </Row>
                    </div>
                </div>
            </div>

        )

    }
}
