import React from "react";
import './style.less'
import { Carousel, Row,Col } from 'antd';

export default class JoinUs extends React.Component {
    componentDidMount() {
        // setInterval(function() {
        //     const show = document.querySelector('span[data-show]')
        //     const next = show.nextElementSibling || document.querySelector('span:first-child')
        //     const up = document.querySelector('span[data-up]')
        //     if (up) {
        //         up.removeAttribute('data-up')
        //     }
        //     show.removeAttribute('data-show')
        //     show.setAttribute('data-up', '')
        
        //     next.setAttribute('data-show', '')
        // }, 2000)
    }
    render() {
    
        return (
            <div className="join-us">
                <div className="title">
                    <Row className="words" justify="center">
                        <Col span={12} className="find">FIND YOUR</Col>
                        <Col  span={12} className="rollInfo">
                            <Carousel dotPosition={"left"} dots={false} autoplay>
                                <div>
                                <h3>TEAM</h3>
                                </div>
                                <div>
                                <h3>MISSION</h3>
                                </div>
                                <div>
                                <h3>FUTURE</h3>
                                </div>
                            </Carousel>
                        </Col>
                    </Row>
                </div>

                <div className="find-us">
                    <h2>JOIN US: hr@metridynamic.com</h2>
                </div>
            </div>
        )
    }
}