import React from "react";
import QueueAnim from 'rc-queue-anim';
import './index.less'
import { Card, Row, Col, Collapse } from 'antd';
import TweenOne from 'rc-tween-one';
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';

const { Panel } = Collapse;
const { Meta } = Card;

export default class OrthographicContent1 extends React.Component {


    render() {
        const cardStyle = {
            width: 420,
            padding: 8,
            margin: "0 auto",
            border: "none"
        }
        const style = { background: '#0092ff', padding: '8px 0' };
        return (
            <ScrollOverPack className='content-wrapper page Orthographic-content1'>
                <Row justify="center">
                    <Col className={"page-box"} xs={20} sm={16} md={12} lg={11} xl={11}>
                        <h1 className="m6">RIE-M10Pro</h1>
                        <TweenOne
                            key="image"
                            className="image-wrapper Orthographic-content-image1"
                            animation={{ x: 0, opacity: 1, ease: 'easeOutQuad' }}
                            style={{ transform: 'translateX(-100px)', opacity: 0 }}
                        />
                        
                        <p>
                            Medium format tilt photography camera, medium format 100 million pixel single lens gimbal camera for super tall building modeling.
                        </p>
                    </Col>
                    <Col className={"page-box"} xs={20} sm={16} md={12} lg={11} xl={11}>
                        <QueueAnim
                            key="QueueAnim"
                            type={['bottom', 'top']}
                            delay={200}
                            className='text-wrapper Orthographic-text'
                        >
                            <Row gutter={[16, 24]}>
                            <Col className="gutter-row" span={12}>
                                    <img src="https://qiniu.metridynamic.com/image/latestProducts/6.png" style={{width:'64px',height: '64px'}} alt={""} />
                                    <p>New image coding</p>
                                </Col>
                                <Col className="gutter-row" span={12}>
                                    <img src="https://qiniu.metridynamic.com/image/latestProducts/5.png" style={{width:'64px',height: '64px'}} alt={""} />
                                    <p>Phase-free contro</p>
                                </Col>
                                <Col className="gutter-row" span={12}>
                                    <img src="https://qiniu.metridynamic.com/image/latestProducts/7.png" style={{width:'64px',height: '64px'}} alt={""} />
                                    <p>MLS shutter</p>
                                </Col>
                                
                                <Col className="gutter-row" span={12}>
                                    <img src="https://qiniu.metridynamic.com/image/latestProducts/3.png" style={{width:'64px',height: '64px'}} alt={""} />
                                    <p>pan-tilt</p>
                                </Col>
                                <Col className="gutter-row" span={12}>
                                    <img src="https://qiniu.metridynamic.com/image/latestProducts/4.png" style={{width:'64px',height: '64px'}} alt={""} />
                                    <p>Redundant data culling</p>
                                </Col>
                                
                                <Col className="gutter-row" span={12}>
                                    <img src="https://qiniu.metridynamic.com/image/latestProducts/2.png" style={{width:'64px',height: '64px'}} alt={""} />
                                    <p>MLE lens</p>
                                </Col>
                            </Row>
                        </QueueAnim>
                    </Col>
                </Row>


            </ScrollOverPack>
        )

    }
}
