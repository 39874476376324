import React from "react";
import QueueAnim from 'rc-queue-anim';
import './index.less'
import { Col, Row,Image } from "antd";
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';
export default class SimulatorMobileBanner extends React.Component {
    componentDidMount() {
        
    }

    render() {
        return (
            <div className='simulator-mobile-banner'>
                <div className="simulator-banner-content">
                    <h1><Texty>Flight simulation by one click</Texty></h1>
                    <Image src="http://qiniu.metridynamic.com/image/new-official-en/simulator/20220419-151507.png"/>
                </div>
            </div>
        )

    }
}
