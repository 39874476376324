import React from "react";
import QueueAnim from 'rc-queue-anim';
import './index.less'
import { Card, Row, Col, Collapse, Carousel } from 'antd';

const { Meta } = Card;

export default class MobileLidarContent1 extends React.Component {
    callback(key) {
        console.log(key);
    }

    render() {
        const cardStyle = {
            width: 280,
            padding: 8,
            margin: "0 auto",
            background: "transparent",
            border: "none"
        }
        return (
            <div className='mobile-lidar-content1'>
                <Carousel autoplay>
                    <div>
                        <Card
                            
                            cover={<img alt="example" src="http://qiniu.metridynamic.com/image/new-official-en/lidar/UVX-1LR.png" />}
                            style={cardStyle}
                        >
                            <Meta title="miniVUX-1LR" description="" />

                        </Card>
                    </div>
                    <div>
                        <Card
                            
                            cover={<img alt="example" src="http://qiniu.metridynamic.com/image%2Fnew-official-en%2Flidar%2F20220427-140950.png" />}
                            style={cardStyle}
                        >
                            <Meta title="VUX-1LR²²" description="" />

                        </Card>
                    </div>
                    <div>
                        <Card
                            
                            cover={<img alt="example" src="http://qiniu.metridynamic.com/image/new-official-en/lidar/UVX-120.png" />}
                            style={cardStyle}
                        >
                            <Meta title="VUX-120" description="" />
                        </Card>
                    </div>

                </Carousel>
            </div>
        )

    }
}
