import React from "react";
import QueueAnim from 'rc-queue-anim';
import './style.less'
import {Card, Row, Col} from 'antd';

const {Meta} = Card;

export default class ParametersContent extends React.Component {

    render() {
        return (
            <div className="gimbal-description-container">
                <div className="description">
                    <div className='line'>
                        <Row>
                            <Col span="8">
                                <img src="http://qiniu.metridynamic.com/image/new-official-en/Gimbal/006.png" alt={""}/>
                                <p>8mm weak light camera <br/>with better night vision</p>
                            </Col>
                            <Col span="8">
                                <img src="http://qiniu.metridynamic.com/image/new-official-en/Gimbal/002.png" alt={""}/>
                                <p>Powerful target tracking ability</p>
                            </Col>
                            <Col span="8">
                                <img src="http://qiniu.metridynamic.com/image/new-official-en/Gimbal/003.png" alt={""}/>
                                <p>Thermal imaging with <br/>grayscale and color mode</p>
                            </Col>
                        </Row>
                    </div>
                    <div className='line'>
                        <Row>
                            <Col span="6">
                                <img src="http://qiniu.metridynamic.com/image/new-official-en/Gimbal/007.png" alt={""}/>
                                <p>30x zoom, 1080EO</p>
                            </Col>
                            <Col span="6">
                                <img src="http://qiniu.metridynamic.com/image/new-official-en/Gimbal/005.png" alt={""}/>
                                <p>Automatically follow <br/>and monitor the target.</p>
                            </Col>
                            <Col span="6">
                                <img src="http://qiniu.metridynamic.com/image/new-official-en/Gimbal/004.png" alt={""}/>
                                <p>Three-axis mechanical <br/>stabilization</p>
                            </Col>
                            <Col span="6">
                                <img src="http://qiniu.metridynamic.com/image/new-official-en/Gimbal/001.png" alt={""}/>
                                <p>High-precisor range finder</p>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

        )

    }
}
