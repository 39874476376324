import react from "react";
import "./style.less"
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import {Row, Col} from "antd";

export default class SimulatorVideos extends react.Component{
    constructor(props) {
        super(props);
    }
    getEnter = (e) => {
        switch (e.index) {
            case 0:
                return {
                    rotate: 90,
                    opacity: 0,
                    y: -60,
                };
            case 10:
            case 1:
                return {
                    y: -60,
                    x: -10,
                    opacity: 0,
                };
            case 9:
            case 2:
                return {
                    y: -60,
                    x: 20,
                    opacity: 0,
                };
            case 3:
                return {
                    y: 60,
                    opacity: 0,
                };
            case 8:
            case 4:
                return {
                    x: 30,
                    opacity: 0,
                };
            case 5:
                return {
                    enter: [
                        {
                            scale: 2,
                            opacity: 0,
                            type: 'set',
                        },
                        { scale: 1.2, opacity: 1, duration: 300 },
                        { scale: 0.9, duration: 200 },
                        { scale: 1.05, duration: 150 },
                        { scale: 1, duration: 100 },
                    ],
                    leave: {
                        opacity: 0, scale: 0,
                    },
                };
            case 6:
                return {
                    scale: 0.8,
                    x: 30,
                    y: -10,
                    opacity: 0,
                };
            case 7:
                return {
                    scale: 0.8,
                    x: 30,
                    y: 10,
                    opacity: 0,
                };
            default:
                return {
                    opacity: 0,
                };
        }
    }
    render() {
        return (
            <ScrollOverPack className="simulator-videos">
                <h2 className="title">
                    <Texty enter={this.getEnter} leave={this.getEnter}>{'Simulator Screenshots'}</Texty>
                </h2>
                <QueueAnim delay={300} className="videos">
                    <Row  key="row">
                        <Col span={"12"}>
                            <div key="a" className={"video-item"}>
                                <video
                                 x5-playsinline="true"
                                 playsinline="true" 
                                 webkit-playsinline="true"
                                src="http://qiniu.metridynamic.com/video/simulator2.mp4" autoPlay loop muted>

                                </video>
                                <div className="item-title">
                                    Flight simulation
                                </div>
                                <div className="item-text">
                                    <p>Real-world flight simulation system can realize manual and fully autonomous flight simulation.The system is equipped with an excellent flight simulation platform. Pre-set airports around the world have exquisite picture quality and rich details.</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={"12"}>
                            <div key="b" className={"video-item"}>
                                <video
                                 x5-playsinline="true"
                                 playsinline="true" 
                                 webkit-playsinline="true"
                                src="http://qiniu.metridynamic.com/video/simulator.mp4" autoPlay loop muted>

                                </video>
                                <div className="item-title">Accurate simulation</div>
                                <div className="item-text">
                                    <p>It is able to simulate various weather conditions and are suitable for testing various performances of aircraft (such as wind resistance, etc.). The precise physical model will bring a realistic flight experience, whether it is remote control flight or advanced parameter adjustment.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </QueueAnim>
            </ScrollOverPack>
        )
    }
}
