import React from 'react';
import TweenOne from 'rc-tween-one';
import { Tabs, Radio, Space } from 'antd';
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
const { TabPane } = Tabs;

export default function Page4() {
  return (
    <ScrollOverPack id="page4" className="content-wrapper page" style={{height:"100vh"}}>
      <QueueAnim
        className="text-wrapper-bottom"
        key="text"
        leaveReverse
        type="bottom"
      >
          <Tabs tabPosition="bottom" key='tab'>
              <TabPane tab="Landing in different places-Landing" key="1">
                  <div className='video-tab'>
                      <video autoPlay loop muted style={{width: "100%",height: "100%"}}>
                          <source src="http://qiniu.metridynamic.com/video/LDL.mp4"
                                  type="video/mp4"/>
                      </video>
                  </div>
              </TabPane>
              <TabPane tab="Mountain air relay-In a mountainous environment" key="2">
                  <div className='video-tab'>
                      <video autoPlay loop muted style={{width: "100%",height: "100%"}}>
                          <source src="http://qiniu.metridynamic.com/video/mesh.mp4"
                                  type="video/mp4"/>
                      </video>
                  </div>
              </TabPane>
              <TabPane tab="Target guided" key="3">
                  <div className='video-tab'>
                      <video autoPlay loop muted style={{width: "100%",height: "100%"}}>
                          <source src="http://qiniu.metridynamic.com/video/follow.mp4"
                                  type="video/mp4"/>
                      </video>
                  </div>
              </TabPane>
              <TabPane tab="remote task sharing" key="4">
                  <div className='video-tab'>
                      <video autoPlay loop muted style={{width: "100%",height: "100%"}}>
                          <source src="http://qiniu.metridynamic.com/video/remoteAssit.mp4"
                                  type="video/mp4"/>
                      </video>
                  </div>
              </TabPane>
              <TabPane tab="real-time video sharing" key="5">
                  <div className='video-tab'>
                      <video autoPlay loop muted style={{width: "100%",height: "100%"}}>
                          <source src="http://qiniu.metridynamic.com/video/share.mp4"
                                  type="video/mp4"/>
                      </video>
                  </div>
              </TabPane>
              <TabPane tab="ADS-B" key="6">
                  <div className='video-tab'>
                      <video autoPlay loop muted style={{width: "100%",height: "100%"}}>
                          <source src="http://qiniu.metridynamic.com/video/ADS-B.mp4"
                                  type="video/mp4"/>
                      </video>
                  </div>
              </TabPane>
              <TabPane tab="ELT (Emergency Locator)Safety backup parachute" key="7">
                  <div className='video-tab'>
                      <video autoPlay loop muted style={{width: "100%",height: "100%"}}>
                          <source src="http://qiniu.metridynamic.com/video/location.mp4"
                                  type="video/mp4"/>
                      </video>
                  </div>
              </TabPane>
              <TabPane tab="Parcel delivery" key="8">
                  <div className='video-tab'>
                      <video autoPlay loop muted style={{width: "100%",height: "100%"}}>
                          <source src="http://qiniu.metridynamic.com/video/safe.mp4"
                                  type="video/mp4"/>
                      </video>
                  </div>
              </TabPane>
          </Tabs>
      </QueueAnim>
      {/*<TweenOne*/}
      {/*  key="image"*/}
      {/*  className="image4 bottom-wrapper"*/}
      {/*  animation={{*/}
      {/*    y: 0, opacity: 1, duration: 550, delay: 150, ease: 'easeOutQuad',*/}
      {/*  }}*/}
      {/*  style={{ transform: 'translateY(50px)', opacity: 0 }}*/}
      {/*/>*/}
    </ScrollOverPack>
  );
}
