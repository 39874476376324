import React from "react";
import './style.less';
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';

export default class ServiceBanner extends React.Component{

    render() {
        return (
            <div className={'service-banner'}>
                <h1>
                    <Texty>
                        Service
                    </Texty>
                </h1>
            </div>
        )
    }
}
