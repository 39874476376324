import React from "react";
import './index.less';
import ObliqueBanner from "./components/banner";
import ObliqueContent1 from "./components/content1";
import ObliqueContent2 from "./components/content2";
import ParametersContent from "./components/parametersContent";
import ObliqueVideoContent from "./components/videoContent";
import MobileobliqueContent1 from "./components/mobileContent1";
import { enquireScreen } from 'enquire-js';
let isMobile;
enquireScreen((b) => {
    isMobile = b;
});
export default class ObliqueCamera extends React.Component {
    state = {
        isMobile
    }
    componentDidMount() {
        // 返回顶部
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div className="Oblique">
                <ObliqueBanner />
                {
                    this.state.isMobile
                        ?
                        <MobileobliqueContent1 />
                        :
                        <ObliqueContent1 />
                }
                <ParametersContent />
                <ObliqueContent2 />
                <ObliqueVideoContent />
            </div>
        )

    }
}
