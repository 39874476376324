import React from 'react';
import TweenOne from 'rc-tween-one';
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { RightOutlined } from '@ant-design/icons';
import { Button,Row,Col } from 'antd';
import QueueAnim from 'rc-queue-anim';
import {Link} from "react-router-dom";

export default function Page4() {
    return (
        <ScrollOverPack
        id="page4"
            className="home-content-wrapper page"
        >
            <Row justify="center">
                <Col className={"page-box"} xs={20} sm={16} md={12} lg={11} xl={11}>
                    <QueueAnim
                        className="text-wrapper left-text"
                        key="text"
                        duration={450}
                        type="bottom"
                        leaveReverse
                    >
                        <h2 key="h2">"Full Scan Without Any Trace"</h2>
                        <p key="p" style={{ maxWidth: 260,marginTop:30,fontSize:"1.5rem" }}>LIDAR</p>
                        <p key="p" style={{ fontSize: "2rem", color: "rgb(0,138,214)"  }}>minVUX-1LR/VUX-1LR²²/VUX-120</p>
                        <div key="button">
                                <Link to={"/lidar"}>
                                <span>Learn more</span>
                                    <RightOutlined />
                                </Link>
                        </div>
                    </QueueAnim>
                </Col>
                <Col className={"page-box"} xs={20} sm={16} md={12} lg={11} xl={11}>
                    <TweenOne
                        key="image"
                        className="payload4 image-wrapper"
                        animation={{ x: 0, opacity: 1, ease: 'easeOutQuad' }}
                        style={{ transform: 'translateX(100px)', opacity: 0 }}
                    />
                </Col>
            </Row>
        </ScrollOverPack>
    );
}
