import React from "react";
import QueueAnim from 'rc-queue-anim';
import './index.less'
import {Card, Row, Col, Collapse, Table} from 'antd';

export default class obliqueContent2 extends React.Component {


    render() {
        const cardStyle = {
            width: 420,
            padding: 8,
            margin: "0 auto",
            border:"none"
        }
        const columns = [
            {
                title: '',
                dataIndex: 'name',
                key: 'name',
                align: 'center',
            },
            {
                title: 'DG3M',
                dataIndex: 'DG3M',
                key: 'DG3M',
                align: 'center',
            },
            {
                title: 'DG4M',
                dataIndex: 'DG4M',
                key: 'DG4M',
                align: 'center',
            },
        ];

        const data = [
            {
                key: '1',
                name: 'Number of lenses',
                DG4M:'5',
                DG3M:'5',
            },
            {
                key: '2',
                name: 'focal length',
                DG4M:'Ortho 44mm/Tilt 56mm',
                DG3M:'Ortho 28mm/Tilt 43mm',
            },
            {
                key: '3',
                name: 'Effective Pixels',
                DG4M:'42MP，Total Pixies>=210 million',
                DG3M:'24MP，Total Pixies>=120 million',
            },
            {
                key: '4',
                name: 'Pixel size',
                DG4M:'Full Frame（35.9mm x 24mm）',
                DG3M:'ASP-C (23.5mm x 15.6mm)',
            },
            {
                key: '5',
                name: 'Camera angle',
                DG4M:'45°',
                DG3M:'45°',
            },
            {
                key: '6',
                name: 'Exposure interval',
                DG4M:'≥0.8s',
                DG3M:'≤0.8s',
            },
            {
                key: '7',
                name: 'Hard disk storage space',
                DG4M:'1280GB',
                DG3M:'640GB/1280GB',
            },
            {
                key: '8',
                name: 'Data read',
                DG4M:'Sky-scanner Premium version',
                DG3M:'Sky-scanner Premium version',
            },
            {
                key: '9',
                name: 'Battery on/off',
                DG4M:'Auto on/off',
                DG3M:'Auto on/off',
            },
            {
                key: '10',
                name: 'Workspace',
                DG4M:'-10°C-50°C',
                DG3M:'-10°C-50°C',
            },
            {
                key: '11',
                name: 'humidity',
                DG4M:'90%',
                DG3M:'90%',
            },
            {
                key: '12',
                name: 'size',
                DG4M:'160x160x105mm',
                DG3M:'160x160x105mm',
            },
            {
                key: '13',
                name: 'weight',
                DG4M:'1350g',
                DG3M:'1350g',
            },
        ];
        return (
            <div className='oblique-content2'>
                <QueueAnim
                    key="QueueAnim"
                    type={['bottom', 'top']}
                    delay={200}
                    className='banner0-text-wrapper'
                >

                    <Table key={"table"} columns={columns} dataSource={data} pagination={false}/>
                </QueueAnim>
            </div>
        )

    }
}
