import React from "react";
import './style.less'
import { Carousel, Card, Row, Col, Image } from 'antd'
import { enquireScreen } from 'enquire-js';

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});
export default class GCSContent extends React.Component {
    render() {
        const cardStyle = {
            width: "100%",
            padding: 8,
            margin: "0 auto",
            background: "transparent",
            border: "none"
        }
        if(isMobile){
            return (
                <div className='mobile-gimbal-content1'>
                    <Carousel>
                        <div style={{ height: 320 }}>
                            <Card
                                hoverable
                                cover={<img alt="example" src="https://qiniu.metridynamic.com/%E5%9C%B0%E9%9D%A2%E7%AB%992%E5%AE%98%E7%BD%91%E5%9B%BE1.png" />}
                                style={cardStyle}
                            >
                            </Card>
                        </div>
                        <div>
                            <Card
                                hoverable
                                cover={<img alt="example" src="https://qiniu.metridynamic.com/%E5%9C%B0%E9%9D%A2%E7%AB%992%E5%AE%98%E7%BD%91%E5%9B%BE2.png" />}
                                style={cardStyle}
                            >
                            </Card>
                        </div>
                    </Carousel>
                </div>
            )
        } else {
            return (<div className="gcs-content">
                        <h2>MC2</h2>
                        <Row justify="center">
                            <Col span={10} style={{ textAlign: "center" }}>
                                <Image preview={false} src="https://qiniu.metridynamic.com/%E5%9C%B0%E9%9D%A2%E7%AB%992%E5%AE%98%E7%BD%91%E5%9B%BE1.png" />
                            </Col>
                            <Col span={10} style={{ textAlign: "center" }}>
                                <Image preview={false} src="https://qiniu.metridynamic.com/%E5%9C%B0%E9%9D%A2%E7%AB%992%E5%AE%98%E7%BD%91%E5%9B%BE2.png" />
                            </Col>
                        </Row>
                    </div>
            )
        }
    }
}