import React from "react";
import QueueAnim from 'rc-queue-anim';
import './index.less'
import {Col, Row, Table} from 'antd';
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';

export default class OrthographicContent2 extends React.Component {


    render() {
        const columns = [
            {
                title: 'Camera',
                dataIndex: 'name',
                key: 'name',
                align: 'center',
                width: 650
            },
            {
                title: '',
                dataIndex: 'param',
                key: 'param',
                align: 'center',
                width: 650
            },
        ];

        const data = [
            {
                key: '1',
                name: 'Focal Length',
                param: '35/40/50/56mm',
            },
            {
                key: '2',
                name: 'Effective Pixels',
                param: '61MP',
            },
            {
                key: '3',
                name: 'Sensor Size',
                param: 'Full Frame(35.7mm×23.8mm)',
            },
            {
                key: '4',
                name: 'Exposure Interval',
                param: '≥0.8s',
            },
            {
                key: '5',
                name: 'Storage',
                param: '1280GB',
            },
            {
                key: '6',
                name: 'Power Supply',
                param: 'SkyPort',
            },
            {
                key: '7',
                name: 'Power On/Off',
                param: 'Auto On/Off',
            },
            {
                key: '9',
                name: 'Operating Temperature',
                param: '-10°C-40°C',
            },
            {
                key: '10',
                name: 'Humidity',
                param: '15%-80%',
            },
            {
                key: '11',
                name: 'Size',
                param: '127*77*35mm',
            },
            {
                key: '12',
                name: 'Weight',
                param: '330g',
            }
        ];
        return (
            <ScrollOverPack className='content-wrapper page Orthographic-content2'>
                <Row justify="center">
                    <Col className={"page-box"} span="24">
                        <QueueAnim
                            key="QueueAnim"
                            type={['bottom', 'top']}
                            delay={200}
                            className='text-wrapper Orthographic-text'
                        >
                            <Table columns={columns} dataSource={data} pagination={false}/>
                        </QueueAnim>
                    </Col>
                </Row>


            </ScrollOverPack>
        )

    }
}
