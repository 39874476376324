import React from "react";
import QueueAnim from 'rc-queue-anim';
import './index.less'
import {Card, Col, Collapse, Row} from 'antd';

const {Panel} = Collapse;
const {Meta} = Card;

export default class obliqueContent1 extends React.Component {


    render() {
        const cardStyle = {
            width: 350,
            padding: 8,
            margin: "80px auto 0 auto",
            border: "none",
            background: "transparent"
        }
        return (
            <div className='oblique-content1'>
                <QueueAnim
                    key="QueueAnim"
                    type={['bottom', 'top']}
                    delay={200}
                    className='banner0-text-wrapper'
                >
                    <Row justify="center">
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Card
                                hoverable
                                cover={<img alt="example"
                                            src="http://qiniu.metridynamic.com/image/new-official-en/oblique/DG3M.png"/>}
                                style={cardStyle}
                            >
                            </Card>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Card
                                hoverable
                                cover={<img alt="example"
                                            src="http://qiniu.metridynamic.com/image/new-official-en/oblique/DG4M-%EF%BC%882%EF%BC%89.png"/>}
                                style={cardStyle}
                            >
                            </Card>
                        </Col>
                    </Row>

                </QueueAnim>
            </div>
        )

    }
}
