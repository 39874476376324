import React from "react";
import './style.less';
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';

export default class InsuranceBanner extends React.Component{
    render() {
        return (
          // <div className={'insurance-banner'}>
          <div></div>
        );
    }
}
