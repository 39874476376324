import react from "react";
import "./style.less"
import 'rc-texty/assets/index.css';
import QueueAnim from 'rc-queue-anim';
import {Col, Row} from "antd";

export default class Content0 extends react.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="datalink-content0">
                <QueueAnim delay={300} className="videos">
                    <Row key="row">
                        <Col span={"12"}>
                            <div key="a" className={"video-item item1"}>
                                <div className="item-title">
                                    directional antenna
                                </div>
                                <div className="item-text" style={{bottom: 22}}>
                                    <p>
                                        When the automatic tracking antenna is working, it will always face
                                        the direction of the aircraft to ensure the best communication signal strength.
                                        It is optional when it is above 50km.</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={"12"}>
                            <div key="b" className={"video-item item2"}>
                                <div className="item-title">Omni + directional antenna</div>
                                <div className="item-text">
                                    <p>
                                        The antenna sends and receives signals in a 360-degree plus a certain direction when the antenna is working.In the long-distance communication, the signal
                                        strength will be relatively weak. It is suitable for the communication range of
                                        30km and below.
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </QueueAnim>
            </div>
        )
    }
}
