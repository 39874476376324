import React from "react";
import './index.less';
import {
    Row, Col, Modal, Form,
    Input,
    Button,
    Radio,
    Select,
    Cascader,
    DatePicker,
    InputNumber,
    TreeSelect,
    Switch,
    Collapse
} from 'antd';
const { Panel } = Collapse;

export default class OnlineContent1 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isModalVisible: false
        }
    }
    showModal() {
        this.setState({
            isModalVisible: true
        })
    }
    onFormLayoutChange(data) {

    }
    render() {
        const { isModalVisible } = this.state;
        return (
            <div className="online-content1">
                <div className="banner">
                    {/* <h2 onClick={this.showModal.bind(this)}>I want a demo</h2> */}
                    <h2>I want a demo</h2>
                    <video src="http://qiniu.metridynamic.com/video/new%20video/onlineDemo/demo_x264.mp4" autoPlay muted loop>

                    </video>

                </div>

                <h1>Join in the demo to see our products and solutions in actions!</h1>
                <Row>
                    <Col className="content1" xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <div className="left-content">
                            <div>Topic: <span>Metridynamic surveillance solutions and flight</span></div>
                            <div>Time: <span>Every Thursday 4pm(Beijing Time,GMT+8)</span></div>
                            <div></div>
                            <div className="link">
                                <span><a href="mailto:sales@metridynamic.com?subject=Online demo register from [your company name]&body=Hi there,this is [your name] from [your country], i'm interested in the online demo">Click to reserve a spot</a></span>
                            </div>
                            {/* <div className="link">
                                <Collapse bordered={false} defaultActiveKey={['1']} ghost>
                                    <Panel header="See the previous demo" key="1">
                                        <div className="see">Surveillance solution: <span></span></div>
                                        <div className="see">Lidar solution:</div>
                                        <div className="see">
                                            Mapping and Survey solution:
                                            <span></span>
                                        </div>
                                    </Panel>
                                </Collapse>
                            </div> */}
                        </div>
                    </Col>
                    {/* <Col className="content1" xs={24} sm={24} md={12} lg={10} xl={10} xxl={10}>
                        <div className="content">
                            <div>See the previous demo:</div>
                            <div>Surveillance solution: <span></span></div>
                            <div>Lidar solution:</div>
                            <div>
                                Mapping and Survey solution:
                                <span></span>
                            </div>
                        </div>

                    </Col> */}
                </Row>
                <Modal title="Basic Modal" visible={isModalVisible} onCancel={() => { this.setState({ isModalVisible: false }) }}>
                    <Form
                        // labelCol={{ span: 4 }}
                        // wrapperCol={{ span: 14 }}
                        layout="inline"
                        onValuesChange={this.onFormLayoutChange.bind(this)}
                    >
                        <Form.Item label="">
                            <Input placeholder="First name" />
                        </Form.Item>
                        <Form.Item label="">
                            <Input placeholder="Last name" />
                        </Form.Item>
                        <Form.Item label="">
                            <Input placeholder="Email" />
                        </Form.Item>
                        <Form.Item label="">
                            <Input placeholder="Company" />
                        </Form.Item>
                        <Form.Item label="">
                            <Select placeholder="Country">
                                <Select.Option value="demo">China</Select.Option>
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        )
    }
}