import React from "react";
import './style.less';
import { Row, Col, Image } from "antd";

export default class ServiceContent1 extends React.Component {
    render() {
        let list = [
            {
                key: 1,
                imgSrc: "http://qiniu.metridynamic.com/image/service/service1.png",
                title: "Equipment Repair",
                content: "Metridynamic guarantees the normal operation of various equipment provided to users. If a failure occurs due to equipment quality problems during the warranty period, We will investigate the cause of the failure and repair it for free until the requirements are met, or replace all or part of the detective material parts."
            },
            {
                key: 2,
                imgSrc: "http://qiniu.metridynamic.com/image/service/consulte.png",
                title: "Technical consulting/support services",
                content: "Metridynamic can provide all-round hardware and software for user system expansion, upgrades, docking with other systems, testing, demonstrations, training, etc, and also provide on-door technical services."
            },
            {
                key: 3,
                imgSrc: "http://qiniu.metridynamic.com/image/service/maintain.png",
                title: "System maintenance service",
                content: "The user can carry out daily/periodical mainterance according to the maintenance manual which attached with the aircraft Also, the after-sales servie personnel of Metridynamic can come to the door for the user provides the fllowing system maintenance services."
            },
            {
                key: 4,
                imgSrc: "http://qiniu.metridynamic.com/image/service/update.png",
                title: "Software and hardware update end maintenance",
                content: "MetriDynmic provides operation and maintenance services for the software provideed. At the same time, the development department will continue to improve the original functions of the products, develop new functions, and promote continuous product upgrades. If the system software is naturally upgraded, we promise to provide it to the buyer for free Ensure that the new version is fully compatible with the old version, and the old version can be upgraded to the new version."
            }
        ]
        return (
            <div className={'service-content1'}>
                {
                    list.map(item => {
                        return (
                            <div className={'content-box'} key={item.key}>
                                <div className="point"></div>
                                <Row>
                                    <Col xs={24} sm={24} md={4} lg={4} xl={4} className="icon-img">
                                        <Image preview={false} src={item.imgSrc} />
                                    </Col>
                                    <Col xs={24} sm={24} md={18} lg={18} xl={18} className={"content-desc"}>
                                        <p className={"title"}>{item.title}</p>
                                        <p className={"content"}>{item.content}</p>
                                    </Col>
                                </Row>
                            </div>
                        )
                    })
                }

            </div>
        )
    }
}
