import React from 'react';
import PropTypes from 'prop-types';
import TweenOne from 'rc-tween-one';
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { RightOutlined } from '@ant-design/icons';
import { Button, Row, Col } from 'antd';
import QueueAnim from 'rc-queue-anim';
export default function Page1({ isMobile }) {
  return (
    <ScrollOverPack id="page1" className="content-wrapper page">
      <Row justify='center'>
        <Col className='page-box' xs={20} sm={16} md={12} lg={11} xl={11}>
          <TweenOne
            key="image"
            className="image1 image-wrapper"
            animation={{ x: 0, opacity: 1, ease: 'easeOutQuad' }}
            style={{ transform: 'translateX(-100px)', opacity: 0 }}
          />
        </Col>
        <Col className='page-box' xs={20} sm={16} md={12} lg={11} xl={11}>
          <QueueAnim
            type={isMobile ? 'bottom' : 'right'}
            className="text-wrapper"
            key="text"
            leaveReverse
          >
            <h2 key="h2">Leading aerodynamics<br /> and astonishing L/D ratio</h2>
            <p key="p">
              Our engineers has applied the leading aerodynamic design and bold industrial creation, including inverse tails which holds the aircraft as a landing gear and also balance the aircraft weight so it ensures the steady flying even at max payload; laminar fuselage, which reduces the air resistance to minimum, increasing the cruise speed and pushing the L/D ratio to 21 (Boeing 747 is L/D ratio is 18).</p>
          </QueueAnim>
        </Col>
      </Row>
    </ScrollOverPack>
  );
}
Page1.propTypes = {
  isMobile: PropTypes.bool,
};
