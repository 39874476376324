import React from "react";
import './style.less';
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';

export default class TrainingBanner extends React.Component{

    render() {
        return (
            <div className={'training-banner'}>
                <h1>
                    <Texty>
                        Education
                    </Texty>
                </h1>
                <p>
                    <Texty>
                        The professional training to our customer is an important part of MetriDynamic
                    </Texty>
                </p>
            </div>
        )
    }
}
