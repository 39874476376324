import React from "react";
import './style.less'
import GCSBanner from "./components/banner";
import ParametersContent from "./components/parametersContent";
import GCSContent from "./components/content0";
import ParametersContent2 from "./components/parametersContent2";
import MobileParametersContent from "./components/MobileParametersContent";
import GCSContent1 from "./components/content1";
import { enquireScreen } from 'enquire-js';
let isMobile;
enquireScreen((b) => {
    isMobile = b;
});
export default class GCS extends React.Component {
    state = {
        isMobile
    }
    componentDidMount() {
        // 返回顶部
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div className="gcs">
                <GCSBanner />
                <GCSContent />
                {
                    this.state.isMobile
                        ?
                        <MobileParametersContent />
                        :
                        <ParametersContent />
                }
            </div>
        )
    }
}