import React from "react";
import './index.less';
import AirCraftInfo from "./components/contentAircraftInfo/ContentAircraft";
import {Page1, Page2, Page3} from "./components/content1/index";
import MobileAircraftInfo from './components/mobileContentAircraft'
import {enquireScreen} from 'enquire-js';
import VideoContent from "./components/videoContent";

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

export default class FlyingPlatform extends React.Component {
    constructor(porps) {
        super(porps);
        this.state = {
            isMobile,
        };
    }

    componentDidMount() {

    }

    render() {
        return (
            <div className='flying-platform'>
                {/*<AircraftVideo/>*/}
                {
                    this.state.isMobile
                        ?
                        <MobileAircraftInfo/>
                        :
                        <AirCraftInfo/>
                }
                <Page1 key="page1" isMobile={this.state.isMobile}/>
                {
                    this.state.isMobile
                        ?
                        <div className="flying-bottom-line" style={{
                            width: "83%",
                            height: "0.5px",
                            background: "rgba(254,254,254,0.5)",
                            margin: "0 auto"
                        }}></div>
                        :
                        ""
                }
                <Page2 key="page2"/>
                {
                    this.state.isMobile
                        ?
                        <div className="flying-bottom-line" style={{
                            width: "83%",
                            height: "0.5px",
                            background: "rgba(254,254,254,0.5)",
                            margin: "0 auto"
                        }}></div>
                        :
                        ""
                }
                <Page3 key="page3" isMobile={this.state.isMobile}/>
                <VideoContent/>
            </div>
        )
    }
}
