import React from "react";
import './index.less';
import TextContent from "./components/textContent";
import SimulatorBanner from "./components/banner/banner";
import SimulatorContent from "./components/simulatorContent";
import SimulatorVideos from "./components/videos"
import MobileSimulatorVideos from "./components/MobileVideos";
import SimulatorMobileBanner from "./components/MobileBanner";
import { enquireScreen } from 'enquire-js';

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

export default class Simulator extends React.Component{
    constructor(porps) {
        super(porps);
        this.state = {
            isMobile,
        };
    }
    componentDidMount() {

    }

    render() {
        return (
            <div className='simulator'>
                {
                    this.state.isMobile
                    ?
                    <SimulatorMobileBanner/>
                    :
                    <SimulatorBanner isMobile={this.state.isMobile}/>
                }
                <TextContent/>
                <SimulatorContent/>
                {
                    this.state.isMobile
                    ?
                    <MobileSimulatorVideos/>
                    :
                    <SimulatorVideos/>
                }
            </div>
        )
    }
}
