import React from "react";
import './style.less'
import QueueAnim from 'rc-queue-anim';
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';
export default class GCSBanner extends React.Component {
    
    render() {
        return (
            <div className="gcs-banner">
                <div className="gcs-banner-content">
                    <h1><Texty>Dual Screen Display, Easy to Control</Texty></h1>
                    <div className="video-container video">
                    <video
                     x5-playsinline="true"
                     playsinline="true" 
                     webkit-playsinline="true"
                    src="https://qiniu.metridynamic.com/%E5%9C%B0%E9%9D%A2%E7%AB%99%E5%AE%98%E7%BD%91%E5%8A%A8%E7%94%BB.mp4" autoPlay loop muted>

                    </video>
                </div>
                </div>
            </div>
        )
    }
}