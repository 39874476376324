import React from "react";
import QueueAnim from 'rc-queue-anim';
import './style.less'
import {Card, Row, Col} from 'antd';

const {Meta} = Card;

export default class MobileParametersContent extends React.Component {

    render() {
        return (
            <div className="mobile-gimbal-description-container">
                <div className="description">
                    <div className='line'>
                        <Row>
                        <Col span="12" className="line-item">
                                <div className="line-box">
                                    <div className="line-box-item">
                                    <img src="http://qiniu.metridynamic.com/image/new-official-en/Gimbal/006.png" alt={""}/>
                                    </div>
                                    <div className="line-box-item" style={{ 'width': 'max-content' }}>
                                    <p>8mm weak light camera <br/>with better night vision</p>
                                    </div>
                                </div>
                            </Col>
                            <Col span="12" className="line-item">
                                <div className="line-box">
                                    <div className="line-box-item">
                                    <img src="http://qiniu.metridynamic.com/image/new-official-en/Gimbal/002.png" alt={""}/>
                                    </div>
                                    <div className="line-box-item">
                                    <p>Powerful target tracking ability</p>
                                    </div>
                                </div>
                            </Col>
                            <Col span="12" className="line-item">
                                <div className="line-box">
                                    <div className="line-box-item">
                                    <img src="http://qiniu.metridynamic.com/image/new-official-en/Gimbal/003.png" alt={""}/>
                                    </div>
                                    <div className="line-box-item">
                                    <p>Thermal imaging with <br/>grayscale and color mode</p>
                                    </div>
                                </div>
                            </Col>
                            <Col span="12" className="line-item">
                                <div className="line-box">
                                    <div className="line-box-item">
                                    <img src="http://qiniu.metridynamic.com/image/new-official-en/Gimbal/007.png" alt={""}/>
                                    </div>
                                    <div className="line-box-item">
                                    <p>30x zoom, 1080EO</p>
                                    </div>
                                </div>
                            </Col>
                            <Col span="12" className="line-item">
                                <div className="line-box">
                                    <div className="line-box-item">
                                    <img src="http://qiniu.metridynamic.com/image/new-official-en/Gimbal/005.png" alt={""}/>
                                    </div>
                                    <div className="line-box-item">
                                    <p>Automatically follow <br/>and monitor the target.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col span="12" className="line-item">
                                <div className="line-box">
                                    <div className="line-box-item">
                                    <img src="http://qiniu.metridynamic.com/image/new-official-en/Gimbal/004.png" alt={""}/>
                                    </div>
                                    <div className="line-box-item">
                                    <p>Three-axis mechanical <br/>stabilization</p>
                                    </div>
                                </div>
                            </Col>
                            <Col span="12" className="line-item">
                                <div className="line-box">
                                    <div className="line-box-item">
                                    <img src="http://qiniu.metridynamic.com/image/new-official-en/Gimbal/001.png" alt={""}/>
                                    </div>
                                    <div className="line-box-item">
                                    <p>High-precisor range finder</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

        )

    }
}
