import React from 'react';
import TweenOne from 'rc-tween-one';
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { RightOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import QueueAnim from 'rc-queue-anim';
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';

export default function Page2() {
  return (
    <ScrollOverPack
      id="page2"
      className="content-wrapper page"
    >
      <Row justify='center'>
        <Col className='page-box' xs={20} sm={16} md={12} lg={11} xl={11}>
          <QueueAnim
            className="text-wrapper left-text"
            key="text"
            duration={450}
            type="bottom"
            leaveReverse
          >
            <h2 key="h2">A Flying Beast</h2>
            <p key="p">
              With better structural and aerodynamic design, P series got excellent performance in terms of counter wind restaince during cruising, (6g force was tested for wing rigidity in the lab). And the biggest capability of carrying payload and endurance at 15kg class.  
            </p>
            <div className="param" style={{ marginTop: 70 }}>
              {/*<Row key="row1">*/}
              {/*  <Col span="8" style={{marginBottom:40}}>*/}
              {/*    <h2><Texty>4h</Texty></h2>*/}
              {/*    <h4><Texty>max flight time</Texty></h4>*/}
              {/*  </Col>*/}
              {/*  <Col span="8">*/}
              {/*    <h2><Texty>6kg</Texty></h2>*/}
              {/*    <h4><Texty>max payload</Texty></h4>*/}
              {/*  </Col>*/}
              {/*  <Col span="8">*/}
              {/*    <h2><Texty>5000m</Texty></h2>*/}
              {/*    <h4><Texty>max flight height</Texty></h4>*/}
              {/*  </Col>*/}
              {/*</Row>*/}
              {/*<Row key="row2">*/}
              {/*  <Col span="12">*/}
              {/*    <h2><Texty>Level 6</Texty></h2>*/}
              {/*    <h4><Texty>max wind resistance</Texty></h4>*/}
              {/*  </Col>*/}
              {/*  <Col span="12">*/}
              {/*    <h2><Texty>36m/s</Texty></h2>*/}
              {/*    <h4><Texty>max flight speed</Texty></h4>*/}
              {/*  </Col>*/}
              {/*</Row>*/}
            </div>
          </QueueAnim>
        </Col>
        <Col className='page-box' xs={20} sm={16} md={12} lg={11} xl={11}>
          <TweenOne
            key="image"
            className="image2 image-wrapper"
            animation={{ x: 0, opacity: 1, ease: 'easeOutQuad' }}
            style={{ transform: 'translateX(100px)', opacity: 0 }}
          />
        </Col>
      </Row>
    </ScrollOverPack>
  );
}
