import React from "react";
import './style.less';
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';
import { Row, Col } from 'antd'

export default class AboutUs extends React.Component {
    render() {
        return (
            <div className={'about-us'}>
                <div className={"title"}>
                    <Texty
                        mode="random"
                    >
                        About Us
                    </Texty>
                </div>
                <div className={"about-desc"}>
                    <p>
                        MetriDynamic is located in Chengdu, China and was established in 2017.
                        The company is committed to the development and production of high-efficiency electric aircraft used in data collection,
                        surveillance, cargo and manned transportation.
                    </p>
                        <div className="split-line"></div>
                    <p>
                        MetriDynamic was founded by an aircraft fanatical technical team.
                        The team was born out of research and development. It mastered the core aerodynamic design and manufacturing of aircraft including composite core material, mechanized mass production, and finally Forming, assembly and testing.
                        </p>
                        <div className="split-line"></div>

                    <p>
                        Through excellent cost, quality control, and efficient production capacity, the P-series VTOL,
                        which currently breaks the world record for electric VTOL under 25 kg. It has been widely used in surveying and mapping, security, emergency, inspection and other fields. In the international market, it is especially favored by military and police customers in various countries.
                    </p>
                </div>
                <div className={'about-desc1'}>
                    <div className={'about-line1'}>
                        <Row justify="center">
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} className="line1">
                                Established in <span>2017</span>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} className="line1">
                                R&D for <span>3</span> years
                            </Col>
                        </Row>
                    </div>
                    <div className={'about-line2'}>
                        <Row>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="line1">
                                <span>2000+ </span>tests
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="line1">
                                Lower than <span>1/1000</span> accident rate
                            </Col>
                        </Row>
                    </div>
                    <div className={'branding'}>
                        <div className={'title'}>
                            Our Goal
                        </div>
                        <Row justify="center">
                            <Col xs={20} sm={16} md={16} lg={12} xl={12}>
                                <div className={"brand-desc"}>
                                    <div className={"brand-line"}>
                                        <div className={"brand-line-top"}>
                                            What are we doing?
                                        </div>
                                        <div className={"brand-line-bottom"}>
                                            We provide a holistic ecosystem of new air mobility and we trust that air space in the future will be as busy as road traffic.
                                        </div>
                                    </div>
                                    <div className={"brand-line"}>
                                        <div className={"brand-line-top"}>
                                            Why evtol?
                                        </div>
                                        <div className={"brand-line-bottom"}>
                                            We believe in the bright future of clear and renewable energy and we believe aircraft can have good wings with the magic of electricity                                        </div>
                                    </div>
                                    <div className={"brand-line"}>
                                        <div className={"brand-line-top"}>
                                            How to achieve our goal?
                                        </div>
                                        <div className={"brand-line-bottom"}>
                                            We lay our focus on our thought services, highly reliable products and efficient technologies, which will pave the stones to future success.                                         </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}
