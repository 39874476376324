import React from 'react';
import './banner.less'

export default class SimulatorBanner extends React.Component {
    state = {
        transform : "matrix(2.84571, 0, 0, 2.84571, 0, 300)",
        percentage : "0"
    }
    componentDidMount() {
        let a = 0 , b =0, c = 1;
        window.addEventListener('scroll', (e) => {
            let scrolled = document.documentElement.scrollTop / (document.documentElement.scrollHeight - document.documentElement.clientHeight)
            // let $bannertitle = document.querySelector('.bannertitle')
            let $bigImg = document.querySelector('.bigImg')
            let $f = document.querySelector('.f')
            let $box = document.querySelector('.box')
            a = 2.84571 - scrolled * 2.8;
            b = 2.84571 - scrolled * 2.8;
            c = 300 - scrolled * 180;
            this.setState({
                transform : `matrix(${a}, 0, 0, ${b}, 0, ${c})`,
                percentage: scrolled * 100 + "%"
            })
            // console.log(scrolled, $bigImg.style.transform);

            if (scrolled <= 0.1) {

            } else {
            }

            if (scrolled <= 0.2) {

            } else {
            }

            if (scrolled >= 0.5) {

            } else {

            }

            if (scrolled >= 0.9) {
                this.setState({
                    transform : "matrix(1, 0, 0, 1, 0, 135)"
                })
            } else {

            }
        })
    }

    render() {
        const {isMobile} = this.props;
        return (
            <section className="simulator-banner">
                    <div className="bg-box">
                        <div className="bigImg" style={{transform: this.state.transform}}>
                            <video className="display-video" src="http://qiniu.metridynamic.com/video/simulator.mp4"
                                   type="video/mp4" playsInline autoPlay muted loop>
                            </video>
                        </div>
                        <div className="banner-title" style={{backgroundPositionX: this.state.percentage }}>Flight simulation by one click</div>
                    </div>
            </section>
        );
    }
}

