import React from "react";
import './index.less';
import { Row, Col, Image } from 'antd';

import { enquireScreen } from 'enquire-js';

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});
export default class OnlineContent2 extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div className="online-content2">
                
                <div className="desc1">
                    <p>More than <span>30</span> companies in <span>10</span> countries have joined the online demo.</p>
                </div>
                {/* <div className="person-info">
                    <div className="title">
                        Online demo speaker
                    </div>
                    <Row>
                        <Col span={isMobile?24:4} className="left">
                            <Image className="avatar" preview={false} src="http://qiniu.metridynamic.com/image%2Fnew-official-en%2F%E9%A3%9E%E4%B9%A620220524-100419.png" />
                        </Col>
                        <Col span={isMobile ? 24 : 10} className="right">
                            <h3>Jack.wang</h3>
                            <h4>Global sales director</h4>
                            <p>
                                Jack is one of the co- founders of Metridynamic, he have been lead government projects in over 10 countries globally over the years. 
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={isMobile ? 24 : 4} className="left">
                            <Image className="avatar" preview={false} src="http://qiniu.metridynamic.com/image/new-official-en/frank/20220607-093352.png" />
                        </Col>
                        <Col span={isMobile ? 24 : 10} className="right">
                            <h3>Frank.L</h3>
                            <h4>Sales manager</h4>
                            <p>
                                Frank has been in MetriDynamic ever since he graduated from university.
                                He is one of the core members of the sales team and he has a wealth of experience, especially in the surveillance sector. 
                            </p>
                        </Col>
                    </Row>
                </div> */}
                <div className="question">
                    <div className="line">
                        <div className="title">
                            What you can see from MetriDynamic Demo?
                        </div>
                        <p className="p">
                            Full presentation of Metridynamic portfolio and solutions. A complete workflow of flight demo . followed by Q&A discussion session 
                        </p>
                    </div>
                    <div className="line">
                        <div className="title">
                            How long does last?
                        </div>
                        <p className="p">
                            The demo will be held once a month. And lasts about 60 minutes. It takes place at 4pm China time. 
                        </p>
                    </div>
                    <div className="line">
                        <div className="title">
                            How to join in?
                        </div>
                        <p className="p">
                            Step1: Click "reserve a spot " and send us an email with your information. <br/>
                            Step2: Will receive a demo room link from an email before we start.<br/>
                            Step3: Open up the link at the scheduled time to join the demo.
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}