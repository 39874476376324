import React from "react";
import './style.less';
import TrainingBanner from "./components/banner";
import TrainingContent from "./components/content0";
import TrainingContent1 from "./components/content1";
import MobileServiceContent1 from "./components/mobileContent1";
import { enquireScreen } from 'enquire-js';
let isMobile;
enquireScreen((b) => {
    isMobile = b;
});
export default class Service extends React.Component{
    state = {
        isMobile
    }
    render() {
        return (
            <div className={'service'}>
                <TrainingBanner/>
                <TrainingContent/>
                {
                    this.state.isMobile
                    ?
                    <MobileServiceContent1/>
                    :
                    <TrainingContent1/>
                }
            </div>
        )
    }
}
