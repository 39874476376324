import React from 'react';
import './style.less';
import { Row, Col } from 'antd';
import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';
import { OverPack } from 'rc-scroll-anim';
import PxLoader from "./../../../../static/js/PxLoader"
// import "./../../../../static/js/PxLoaderImage"
import PicLoader from './loader'
class AircraftInfo extends React.PureComponent {
    componentDidMount() {
       // this.loadImage()
        this.loader = new PicLoader()
    }

    render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    const arr = [1,2,3,4,5,6];
    delete props.dataSource;
    delete props.isMobile;
      const style = { background: '#0092ff', padding: '8px 0' };
      const colStyle = {marginBottom:48}
      return (
      <div className='aircraft-info-wrapper'>

              <div className="aircraft-info">
                  <div className="sticky">
                      <canvas className="p4-scrolling" width="1200" height="720">

                      </canvas>
                  </div>
                  <div className="description-container">
                      <div className="description">
                          <h3>Metridynamic P series</h3>
                      </div>

                      <div className="description">
                          <h3>Make Wings for Your Imagination </h3>
                      </div>

                      <div className="description">
                          <p>As a pioneer of latest aerodynamic technology and cutting-edged industrial design, MetriDynamic has always been dedicated to manufacturing qualitied and versatile evtols with great capacity and capability, which has made it the first choice for many individual and industrial customers.  </p>
                      </div>

                      <div className="description">
                         <div className='line'>
                             <Row>
                                 <Col style={colStyle} xs={12} sm={12} md={12} lg={8} xl={8}>
                                     <img src="http://qiniu.metridynamic.com/image%2Fp4%2FPayload%20interchangable.png" alt={""}/>
                                     <p>Payload interchangable</p>
                                 </Col>
                                 <Col style={colStyle} xs={12} sm={12} md={12} lg={8} xl={8}>
                                     <img src="http://qiniu.metridynamic.com/image%2Fp4%2FAll%20electric.png" alt={""}/>
                                     <p>All electric</p>
                                 </Col>
                                 <Col style={colStyle} xs={12} sm={12} md={12} lg={8} xl={8}>
                                     <img src="http://qiniu.metridynamic.com/image/new-official-en/P4icon/level.png" alt={""}/>
                                     <p>Level 6 Wind Resistance</p>
                                 </Col>
                                 <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                     <img src="http://qiniu.metridynamic.com/image/new-official-en/P4icon/distance.png" alt={""}/>
                                      <p>150km Max. Control distance</p>
                                 </Col>
                                 <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                     <img src="http://qiniu.metridynamic.com/image/new-official-en/P4icon/max.png" alt={""}/>
                                     <p>6kg Max Payload</p>
                                 </Col>
                                 <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                                     <img src="http://qiniu.metridynamic.com/image/new-official-en/P4icon/weight.png" alt={""}/>
                                     <p>15kg-class MTOW</p>
                                 </Col>
                             </Row>
                         </div>
                      </div>

                      {/*<div className="description">*/}
                      {/*    <h3>Designed to be seen and heard.</h3>*/}
                      {/*    <p>HomePod is wrapped in a seamless mesh fabric designed for aesthetics and acoustic*/}
                      {/*        performance. Available in white and space gray, it’s gorgeous from every angle — yet*/}
                      {/*        virtually transparent to the sound waves passing through it.</p>*/}
                      {/*</div>*/}
                  </div>
              </div>
      </div>
    );
  }
}

export default AircraftInfo;
