import React from "react";
import PropTypes from "prop-types";
import TweenOne from "rc-tween-one";
import ScrollOverPack from "rc-scroll-anim/lib/ScrollOverPack";
import { Row, Col } from "antd";
import QueueAnim from "rc-queue-anim";
export default function Page1({ isMobile }) {
  return (
    <ScrollOverPack id="page1" className="content-wrapper page">
      <Row justify="center">
        <Col className="page-box" xs={20} sm={16} md={12} lg={11} xl={11}>
          <QueueAnim
            type={isMobile ? "bottom" : "right"}
            className="text-wrapper"
            key="text"
            leaveReverse
          >
            <h2 key="h2">
            Self developed MLE lens, greatly improving the quality of aerial photos!
            </h2>
            <p key="p">
            RuiPo has rich experience in lens design in the field of aerial photography, half-frame tilt camera DG3Pros, full-frame tilt camera DG4Pros can have a very good reputation in the industry, one of the most critical points is to have very good lenses, the lenses are RuiPo self-research, with a unique FOV market angle both to take into account the model effect and to ensure sufficient safety flight height; RuiPo For M10Pro/M15Pro designed two MLE lenses: 50mm/60mm; at the same time, for the above lenses RuiPo configuration aberration correction and edge illumination correction software, can achieve almost the same resolution and illumination from the center to the edge of the aerial film, to ensure better modeling effect; each lens is highly cured with the image sensor to achieve rigid connection, and after strict check and calibration, the internal orientation elements accurately; with RuiPo MLS shutter, it can realize high precision, high sharpness and low distortion aerial images, making the operation more efficient and reliable;
            </p>
          </QueueAnim>
        </Col>
        <Col className="page-box" xs={20} sm={16} md={12} lg={11} xl={11}>
          <TweenOne
            key="image"
            className="image7 image-wrapper"
            animation={{ x: 0, opacity: 1, ease: "easeOutQuad" }}
            style={{ transform: "translateX(-100px)", opacity: 0 }}
          />
        </Col>
      </Row>
    </ScrollOverPack>
  );
}
Page1.propTypes = {
  isMobile: PropTypes.bool,
};
