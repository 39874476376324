import react from "react";
import './style.less'
import {OverPack} from 'rc-scroll-anim';
import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';
import {Col, Row, Table} from "antd";

export default class SimulatorContent extends react.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const columns = [
            {
                title: 'Item',
                dataIndex: 'name',
                key: 'name',
                render: text => <a>{text}</a>,
            },
            {
                title: 'Parameter',
                dataIndex: 'Parameter',
                key: 'Parameter',
            },
            // {
            //     title: 'Remarks',
            //     dataIndex: 'Remarks',
            //     key: 'Remarks',
            // }
        ];

        const data = [
            {
                key: '1',
                name: 'Weight',
                Parameter: "158g",
                Remarks: '158gFull Carbon Filber Composite Material',
            },
            {
                key: '2',
                name: 'Size',
                Parameter: "80*80*19mm",
                Remarks: '3m',
            },
            {
                key: '3',
                name: 'Size',
                Parameter: "DC 5V",
                Remarks: '1.9m',
            },
            {
                key: '4',
                name: 'Operating temperature',
                Parameter: "0-65℃",
                Remarks: '0.3m',
            },
            {
                key: '5',
                name: 'Power supply interface',
                Parameter: "Type-C",
                Remarks: 'No hand controller required,fully autonomous vertical take-off and landing',
            },
            {
                key: '6',
                name: 'Device interface',
                Parameter: "Type-C,3Pin Molex",
                Remarks: '≤15kg',
            },
        ];
        return (
            <OverPack className="simulator-content">
                <Row>
                    <Col xs={24} sm={24} md={12} lg={12} xl={10}>
                        <TweenOne key="0" animation={{opacity: 1}}
                                  className="simulator-img"
                                  style={{opacity: 0, marginBottom: 10}}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={14}>
                        <QueueAnim key="queue" leaveReverse>
                            <div key="a" className="code-box-shape queue-anim-demo">
                                <Table columns={columns} dataSource={data} pagination={false}
                                       title={() => "Specification"}/>
                            </div>
                        </QueueAnim>
                    </Col>

                </Row>
            </OverPack>
        )
    }
}
