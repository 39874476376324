import React from "react";
import QueueAnim from 'rc-queue-anim';
import './style.less'
import { Card, Row, Col } from 'antd';

const { Meta } = Card;

export default class MobileParametersContent extends React.Component {

    render() {
        return (
            <div className="mobile-gcs-description-container">
                <div className="description">
                    <div className='line'>
                        <Row>
                            <Col span="24" className="line-item">
                                <div className="line-box">
                                    <div className="line-box-item">
                                    <img src="http://qiniu.metridynamic.com/image/new-official-en/GCS/2screen.png" alt={""} />
                                    </div>
                                    <div className="line-box-item">
                                    <p>Dual-screen design, flight route <br/>and video separate display</p>
                                    </div>
                                </div>
                            </Col>
                            <Col span="24" className="line-item">
                                <div className="line-box">
                                    <div className="line-box-item">
                                    <img src="http://qiniu.metridynamic.com/image/new-official-en/GCS/controller.png" alt={""} />

                                    </div>
                                    <div className="line-box-item">
                                    <p>Portable ground station box with wheels, <br/>hand-pull, shockproof and dustproof</p>
                                    </div>
                                </div>
                            </Col>
                            <Col span="24" className="line-item">
                                <div className="line-box">
                                    <div className="line-box-item">
                                    <img src="http://qiniu.metridynamic.com/image/new-official-en/GCS/%E6%8E%A7%E5%88%B6%E9%81%A5%E6%84%9F.png" alt={""} />

                                    </div>
                                    <div className="line-box-item">
                                    <p>Built-in gimbal control joystick, <br/>smooth and precise</p>
                                    </div>
                                </div>
                            </Col>
                            <Col span="24" className="line-item">
                                <div className="line-box">
                                    <div className="line-box-item">
                                    <img src="http://qiniu.metridynamic.com/image/new-official-en/GCS/7H.png" alt={""} />

                                    </div>
                                    <div className="line-box-item">
                                    <p>7 hours long battery endurance, <br/>sufficient for all-day use</p>
                                    </div>
                                </div>
                            </Col>
                            <Col span="24" className="line-item">
                                <div className="line-box">
                                    <div className="line-box-item">
                                    <img src="http://qiniu.metridynamic.com/image/new-official-en/GCS/spreadControl.png" alt={""} />

                                    </div>
                                    <div className="line-box-item">
                                    <p>Ergonomic desgin, reasonable partition <br/>operation without mutual interference</p>
                                    </div>
                                </div>
                            </Col>
                            <Col span="24" className="line-item">
                                <div className="line-box">
                                    <div className="line-box-item">
                                    <img src="http://qiniu.metridynamic.com/image/new-official-en/GCS/%E7%9C%9F%E7%9A%AE%E9%9D%A2%E6%9D%BF.png" alt={""} />

                                    </div>
                                    <div className="line-box-item">
                                    <p>Leather panel, comfortable<br/> operation experience</p>
                                    </div>
                                </div>
                            </Col>
                            <Col span="24" className="line-item">
                                <div className="line-box">
                                    <div className="line-box-item">
                                    <img src="http://qiniu.metridynamic.com/image/new-official-en/GCS/%E7%94%B5%E5%AD%90%E8%AE%BE%E5%A4%87%E5%85%85%E7%94%B5.png" alt={""} />

                                    </div>
                                    <div className="line-box-item">
                                    <p>The built-in battery of the ground station <br/>can charge electronic devices such as <br/>laptops, mobile phones, etc</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

        )

    }
}
