import React from "react";
import './index.less';
import OnlineContent1 from "./components/content1";
import OnlineContent2 from "./components/content2";
export default class OnlineDemo extends React.Component{
    constructor(props){
        super(props)
    }
    render(){
        return (
            <div>
                <OnlineContent1/>
                <OnlineContent2/>
            </div>
        )
    }
}