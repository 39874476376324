import React from 'react';
import TweenOne from 'rc-tween-one';
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';
import {Col, Row} from 'antd';
import './index.less'
import {Link} from 'react-router-dom';
import classNames from 'classnames'

export default class CraftInfo extends React.Component {
    state = {
        show: true,
        showP4: true,
    }

    getChildrenToRender = (list) => {
        const style1 = { color: "#008ad6", marginBottom: 0};
        const style2 = {color: "#fff"};
        return list.map((i, index) => {
            return <Col className="gutter-row word-line" xs={24} sm={24} md={8} lg={8} xl={8} key={index}>
                <div className='h1' style={style1}>{i.title}</div>
                <div className='p' style={style2}>{i.disc}</div>
            </Col>
        })
    }
    geInterval = (e) => {
        switch (e.index) {
            case 0:
                return 0;
            case 1:
                return 150;
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
                return 150 + 450 + (e.index - 2) * 10;
            default:
                return 150 + 450 + (e.index - 6) * 150;
        }
    }
    getEnter = (e) => {
        const t = {
            opacity: 0,
            scale: 0.8,
            y: '-100%',
        };
        if (e.index >= 2 && e.index <= 6) {
            return {...t, y: '-30%', duration: 150};
        }
        return t;
    }

    getSplit = (e) => {
        const t = e.split(' ');
        const c = [];
        t.forEach((str, i) => {
            c.push((
                <span key={`${str}-${i}`}>
          {str}
        </span>
            ));
            if (i < t.length - 1) {
                c.push(<span key={` -${i}`}> </span>);
            }
        });
        return c;
    }

    render() {
        const list4 = [
            {
                title: "4h",
                disc: "Max. Endurance"
            },
            {
                title: "Level 6",
                disc: "Wind Resistance"
            },
            {
                title: "100km",
                disc: "Max. Control Distance"
            },
            {
                title: "6kg",
                disc: "Max. Payload"
            },
            {
                title: "3m",
                disc: "Wingspan"
            },
            {
                title: "15kg",
                disc: "MTOW"
            },
        ]
        const list6 = [
            {
                title: "6h",
                disc: "Max. Endurance"
            },
            {
                title: "Level 6",
                disc: "Wind Resistance"
            },
            {
                title: "150km",
                disc: "Max. Control Distance"
            },
            {
                title: "6kg",
                disc: "Max. Payload"
            },
            {
                title: "3.8m",
                disc: "Wingspan"
            },
            {
                title: "16kg",
                disc: "MTOW"
            },
        ]
        const childrenToRender4 = this.getChildrenToRender(list4);
        const childrenToRender6 = this.getChildrenToRender(list6);
        if (this.state.showP4) {
            return (
                <ScrollOverPack id="craft" className="craft-info">
                    <QueueAnim
                        className="text-wrapper-top"
                        key="left"
                        duration={1450}
                        type="bottom"
                        leaveReverse
                    >
                        {this.state.show && (
                            <div className="combined">
                                <div className="combined-shape">
                                    <div className="shape-left">
                                        <TweenOne
                                            animation={[
                                                {x: 158, type: 'from', ease: 'easeInOutQuint', duration: 600},
                                                {x: -158, ease: 'easeInOutQuart', duration: 450, delay: -150},
                                            ]}
                                        />
                                    </div>
                                    <div className="shape-right">
                                        <TweenOne
                                            animation={[
                                                {x: -158, type: 'from', ease: 'easeInOutQuint', duration: 600},
                                                {x: 158, ease: 'easeInOutQuart', duration: 450, delay: -150},
                                            ]}
                                        />
                                    </div>
                                </div>
                                <Texty
                                    className="title"
                                    type="mask-top"
                                    enter={this.getEnter}
                                    interval={this.geInterval}
                                    component={TweenOne}
                                    componentProps={{
                                        animation: [
                                            {x: 130, type: 'set'},
                                            {x: 100, delay: 500, duration: 450},
                                            {
                                                ease: 'easeOutQuart',
                                                duration: 300,
                                                x: 0,
                                            },
                                            {
                                                letterSpacing: 0,
                                                delay: -300,
                                                scale: 0.9,
                                                ease: 'easeInOutQuint',
                                                duration: 1000,
                                            },
                                            {scale: 1, width: '100%', ease: 'easeInOutQuint'},
                                        ],
                                    }}
                                >
                                    MetriDynamic P4
                                </Texty>
                                <TweenOne
                                    className="combined-bar"
                                    animation={{delay: 2000, width: 0, x: 158, type: 'from', ease: 'easeInOutExpo'}}
                                />
                                <Texty
                                    className="content"
                                    type="bottom"
                                    split={this.getSplit}
                                    delay={1800}
                                    interval={30}
                                >
                                    {/* Animation specification and components of Ant Design. */}
                                </Texty>
                                <p className='learn-more'><Link to="/aircraft">Learn more</Link></p>
                            </div>
                        )}
                        {/*<h1>Introducing the<br/> Metridynamic P4</h1>*/}
                    </QueueAnim>
                    <TweenOne
                        key="image"
                        className="aircraft-img bottom-wrapper"
                        animation={{
                            y: 0, opacity: 1, duration: 550, delay: 150, ease: 'easeOutQuad',
                        }}
                        style={{transform: 'translateY(50px)', opacity: 0}}
                    />
                    <div><div className='p-4'></div></div>
                    <QueueAnim
                        className="text-wrapper-bottom"
                        key="text"
                        duration={1450}
                        type="left"
                        leaveReverse
                    >
                        <Row gutter={[16, 24]} key="a" style={{margin: 0}}>
                            {childrenToRender4}
                        </Row>
                    </QueueAnim>
                    <Row justify="center">
                        <div className="row-me row-center">
                            <div className={classNames('btn1', {'btn1-active': this.state.showP4})} onClick={() => {
                                this.setState({
                                    showP4: true
                                })
                            }}>P4
                            </div>
                            <div className={classNames('btn2', {'btn2-active': !this.state.showP4})}
                                 onClick={() => {
                                     this.setState({
                                         showP4: false
                                     })
                                 }}>P6
                            </div>
                        </div>
                    </Row>
                </ScrollOverPack>
            );
        } else {
            return (
                <ScrollOverPack id="craft" className="craft-info">
                    <QueueAnim
                        className="text-wrapper-top"
                        key="left"
                        duration={1450}
                        type="bottom"
                        leaveReverse
                    >
                        {this.state.show && (
                            <div className="combined">
                                <div className="combined-shape">
                                    <div className="shape-left">
                                        <TweenOne
                                            animation={[
                                                {x: 158, type: 'from', ease: 'easeInOutQuint', duration: 600},
                                                {x: -158, ease: 'easeInOutQuart', duration: 450, delay: -150},
                                            ]}
                                        />
                                    </div>
                                    <div className="shape-right">
                                        <TweenOne
                                            animation={[
                                                {x: -158, type: 'from', ease: 'easeInOutQuint', duration: 600},
                                                {x: 158, ease: 'easeInOutQuart', duration: 450, delay: -150},
                                            ]}
                                        />
                                    </div>
                                </div>
                                <Texty
                                    className="title"
                                    type="mask-top"
                                    enter={this.getEnter}
                                    interval={this.geInterval}
                                    component={TweenOne}
                                    componentProps={{
                                        animation: [
                                            {x: 130, type: 'set'},
                                            {x: 100, delay: 500, duration: 450},
                                            {
                                                ease: 'easeOutQuart',
                                                duration: 300,
                                                x: 0,
                                            },
                                            {
                                                letterSpacing: 0,
                                                delay: -300,
                                                scale: 0.9,
                                                ease: 'easeInOutQuint',
                                                duration: 1000,
                                            },
                                            {scale: 1, width: '100%', ease: 'easeInOutQuint'},
                                        ],
                                    }}
                                >
                                    MetriDynamic P6
                                </Texty>
                                <TweenOne
                                    className="combined-bar"
                                    animation={{delay: 2000, width: 0, x: 158, type: 'from', ease: 'easeInOutExpo'}}
                                />
                                <Texty
                                    className="content"
                                    type="bottom"
                                    split={this.getSplit}
                                    delay={1800}
                                    interval={30}
                                >
                                    {/* Animation specification and components of Ant Design. */}
                                </Texty>
                                <p className='learn-more'><Link to="/aircraft">Learn more</Link></p>
                            </div>
                        )}
                        {/*<h1>Introducing the<br/> Metridynamic P4</h1>*/}
                    </QueueAnim>
                    <TweenOne
                        key="image"
                        className="aircraft-img bottom-wrapper"
                        animation={{
                            y: 0, opacity: 1, duration: 550, delay: 150, ease: 'easeOutQuad',
                        }}
                        style={{transform: 'translateY(50px)', opacity: 0}}
                    />
                    <div><div className='p-6'></div></div>
                    <QueueAnim
                        className="text-wrapper-bottom"
                        key="text"
                        duration={1450}
                        type="left"
                        leaveReverse
                    >
                        <Row gutter={[16, 24]} key="a" style={{margin: 0}}>
                            {childrenToRender6}
                        </Row>
                    </QueueAnim>
                    <Row justify="center">
                        <div className="row-me row-center">
                            <div className={classNames('btn1', {'btn1-active': this.state.showP4})} onClick={() => {
                                this.setState({
                                    showP4: true
                                })
                            }}>P4
                            </div>
                            <div className={classNames('btn2', {'btn2-active': !this.state.showP4})}
                                 onClick={() => {
                                     this.setState({
                                         showP4: false
                                     })
                                 }}>P6
                            </div>
                        </div>
                    </Row>
                </ScrollOverPack>
            );
        }
    }
}
