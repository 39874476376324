import React from "react";
import './style.less'
import { Row, Col, Image } from 'antd'

export default class HowToMakeAClaim extends React.Component {
    render() {
        const gutter = {
            xs: 8, sm: 16, md: 24, lg: 32
        }
       
        const list = [
            {
                key: 1,
                bgSrc: "http://qiniu.metridynamic.com/image/new-official-en/insurance/6.png",
                desc: "Provide video evidence of the flight scene"
            },
            {
                key: 2,
                bgSrc: "http://qiniu.metridynamic.com/image/new-official-en/insurance/7.png",
                desc: "The insurance company receives the evidence of the accident, reviews and records it"
            },
            {
                key: 3,
                bgSrc: "http://qiniu.metridynamic.com/image/new-official-en/insurance/8.png",
                desc: "The equipment transported back to the factory"
            },
            {
                key: 4,
                bgSrc: "http://qiniu.metridynamic.com/image/new-official-en/insurance/9.png",
                desc: "The manufacturer assesses the damage of the equipment"
            },
            {
                key: 5,
                bgSrc: "http://qiniu.metridynamic.com/image/new-official-en/insurance/10.png",
                desc: "The insurance company assesses the loss and confirms the payment"
            },
            {
                key: 6,
                bgSrc: "http://qiniu.metridynamic.com/image/new-official-en/insurance/11.png",
                desc: "The manufacturer receives the compensation and starts the repair"
            },
            {
                key: 7,
                bgSrc: "http://qiniu.metridynamic.com/image/new-official-en/insurance/12.png",
                desc: "Transport and return after repair"
            },
            {
                key: 8,
                bgSrc: "http://qiniu.metridynamic.com/image/new-official-en/insurance/5.png",
                desc: "Payment claimed"
            },
        ]
        return (
            <div className={'how-to-make-a-claim'}>
                <h1>How to make a claim?</h1>
                <div className={'buy-process'}>
                    <Row justify="center">
                        {
                            list.map(item => {
                                return <Col className="col-item" xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                                    <div className="item-box">
                                        <div className="num">{item.key}</div>
                                        <div className="icon-img">
                                            <Image preview={false} src={item.bgSrc} />
                                        </div>
                                        <p className={`item-desc${item.key}`}>{item.desc}</p>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>

                </div>
            </div>
        )
    }
}
