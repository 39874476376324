import React from "react";
import './style.less'
import {Tabs} from 'antd';
import { enquireScreen } from 'enquire-js';

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

const {TabPane} = Tabs;

export default class GimbalVideoContent extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="gimbal-video-container">
                <div className={"content"}>
                    <Tabs tabPosition={isMobile ? "top" : "left"}>
                        <TabPane tab="Landing in different places" key="1">
                            <div className="video-container video">
                                <video
                                    src="http://qiniu.metridynamic.com/video%2F20220517%E7%BA%BF%E6%80%A7%E5%8A%A8%E7%94%BB%2F%E5%BC%82%E5%9C%B0%E8%B5%B7%E9%99%8D_x264.mp4"
                                    autoPlay loop muted>
                                </video>
                            </div>
                        </TabPane>
                        <TabPane tab="Target guided flight" key="2">
                            <div className="video-container video">
                                <video
                                    src="http://qiniu.metridynamic.com/video%2F20220517%E7%BA%BF%E6%80%A7%E5%8A%A8%E7%94%BB%2F%E7%9B%AE%E6%A0%87%E5%BC%95%E5%AF%BC%E9%A3%9E%E8%A1%8C_x264.mp4"
                                    autoPlay loop muted>
                                </video>
                            </div>
                        </TabPane>
                        <TabPane tab="Remote task sharing" key="3">
                            <div className="video-container video">
                                <video
                                    src="http://qiniu.metridynamic.com/video%2F20220517%E7%BA%BF%E6%80%A7%E5%8A%A8%E7%94%BB%2F%E8%BF%9C%E7%A8%8B%E5%8D%8F%E5%8A%A9_x264.mp4"
                                    autoPlay loop muted>
                                </video>
                            </div>
                        </TabPane>
                        <TabPane tab="Real-time video sharing" key="4">
                            <div className="video-container video">
                                <video
                                    src="http://qiniu.metridynamic.com/video%2Fnew%20video%2F%E5%AE%9E%E6%97%B6%E8%A7%86%E9%A2%91%E5%88%86%E4%BA%AB_x264.mp4"
                                    autoPlay loop muted>
                                </video>
                            </div>
                        </TabPane>
                        <TabPane tab="Mountain air relay" key="5">
                            <div className="video-container video">
                                <video
                                    src="http://qiniu.metridynamic.com/video%2Fnew%20video%2F%E5%B1%B1%E5%8C%BA%E7%A9%BA%E4%B8%AD%E4%B8%AD%E7%BB%A7_x264.mp4"
                                    autoPlay loop muted>
                                </video>
                            </div>
                        </TabPane>
                        <TabPane tab="MESH multi-node networking" key="6">
                            <div className="video-container video">
                                <video
                                    src="http://qiniu.metridynamic.com/video%2F20220517%E7%BA%BF%E6%80%A7%E5%8A%A8%E7%94%BB%2FMESH%20%E5%A4%9A%E8%8A%82%E7%82%B9%E7%BB%84%E7%BD%91_x264.mp4"
                                    autoPlay loop muted>
                                </video>
                            </div>
                        </TabPane>
                        <TabPane tab="ADS-B Civil Aviation Automatic Broadcasting System" key="7">
                            <div className="video-container video">
                                <video
                                    src="http://qiniu.metridynamic.com/video%2F20220517%E7%BA%BF%E6%80%A7%E5%8A%A8%E7%94%BB%2FADS-B%20%E6%B0%91%E8%88%AA%E8%87%AA%E5%8A%A8%E5%B9%BF%E6%92%AD%E7%B3%BB%E7%BB%9F_x264.mp4"
                                    autoPlay loop muted>
                                </video>
                            </div>
                        </TabPane>
                        <TabPane tab="ELT (Emergency Locator)" key="8">
                            <div className="video-container video">
                                <video
                                    src="http://qiniu.metridynamic.com/video%2F%E5%BA%94%E6%80%A5%E5%AE%9A%E4%BD%8D%E6%A8%A1%E5%9D%97_x264.mp4"
                                    autoPlay loop muted>
                                </video>
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        )

    }
}
