import React from "react";
import './style.less'
import { Tabs } from 'antd';
import { enquireScreen } from 'enquire-js';

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

const { TabPane } = Tabs;

export default class GimbalVideoContent extends React.Component {
    render() {
        return (
            <div className="gimbal-video-container">
                <div className={"content"}>
                    <Tabs tabPosition={isMobile ? "top" : "left"}>
                        <TabPane tab="Course adjustment" key="1">
                            <p>During the tilt photography operation, in order to collect the texture information of the buildings at the edge of the target area, it is necessary to extend the route, and a large number of invalid and redundant aerials will be collected during the flight of the extended route. A large number of invalid aerials will cause the total number of aerials to soar, which will seriously reduce the data processing efficiency and increase the difficulty of internal data processing and the probability of air triple stratification.</p>
                            <div className="video-container video">
                                <video
                                    src="https://qiniu.metridynamic.com/video/latestProducts/route-assistant1.mp4"
                                    autoPlay loop muted>
                                </video>
                            </div>
                        </TabPane>
                        <TabPane tab="Load the 3D model" key="2">
                            <p>Support S3C format model loading, making route planning more intuitive and clear. *It is very helpful in planning elevation routes to ensure load safety and reduce flight accidents Route angle: Adjustable route angle to make the route perpendicular to the complex surface of the building, making the model more detailed.</p>
                            <div className="video-container video">
                                <video
                                    src="https://qiniu.metridynamic.com/video/latestProducts/route-assistant2.mp4"
                                    autoPlay loop muted>
                                </video>
                            </div>
                        </TabPane>
                        <TabPane tab="Route planning" key="3">
                            <p>Rainpooroute assistant route planning software supports the four major route planning functions of grid, surround, imitation ground and elevation for the whole series of RuiPo equipment.
                                Support intelligent partitioning function for large area measurement area, easier operation and higher efficiency when working in large area.
                                Support intelligent flight speed limit, according to the hardware characteristics of the equipment model, set the maximum flight speed threshold, to ensure operational efficiency.
                                Support setting the position and height of the starting point of the route to ensure operational safety.
                                Support route information (measuring area, route length, flight time, number of photos, etc.) statistics function, to avoid invalid flights. </p>
                            <div className="video-container video">
                                <video
                                    src="https://qiniu.metridynamic.com/video/latestProducts/route-assistant3.mp4"
                                    autoPlay loop muted>
                                </video>
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        )

    }
}
