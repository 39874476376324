import React from 'react';
import { Layout } from 'antd';
import {
    UpOutlined
} from '@ant-design/icons';
import 'antd/dist/antd.less';
import './App.less';
import MyHeader from './components/header'
import MyFooter from './components/footer'
import MobileFooter from './components/footer/mobileFooter';
import {
    Nav00DataSource,
    Footer10DataSource,
} from './data.source';
import { enquireScreen } from 'enquire-js';

let isMobile;
enquireScreen((b) => {
    isMobile = b;
});
export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile,
        };
    }
    render() {

        return (
            <Layout className="App">
                <MyHeader
                    id="Nav0_0"
                    key="Nav0_0"
                    dataSource={Nav00DataSource}
                    isMobile={this.state.isMobile}
                />
                <div className='page-container'>
                {this.props.children}
                </div>
                {
                    this.state.isMobile
                    ?
                    <MobileFooter
                    id="Footer1_0"
                    key="Footer1_0"
                    dataSource={Footer10DataSource}
                    isMobile={this.state.isMobile}
                    />
                    :
                    <MyFooter
                    id="Footer1_0"
                    key="Footer1_0"
                    dataSource={Footer10DataSource}
                    isMobile={this.state.isMobile}
                />

                }
             
            </Layout>
        );
    }
}

