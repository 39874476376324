import React from "react";
import QueueAnim from 'rc-queue-anim';
import './index.less'
import {Col, Row} from "antd";

export default class DatalinkBanner extends React.Component {

    render() {
        return (
            <div className='Datalink-banner'>
                <QueueAnim
                    key="QueueAnim"
                    type={['bottom', 'top']}
                    delay={200}
                    className='Datalink-banner-content'
                >
                    <h1>Faster, Farther, Firmer</h1>
                </QueueAnim>
            </div>
        )

    }
}
