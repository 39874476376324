import React from 'react';
import TweenOne from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { Row, Col, Image } from 'antd';
import { getChildrenToRender } from '../../../utils';
import { isImg } from '../../../utils';
import './style.less'
import mapContainer from './../../../components/Map'
import { FacebookOutlined, LinkedinOutlined, YoutubeOutlined } from '@ant-design/icons';
import { enquireScreen } from 'enquire-js';
let isMobile;

enquireScreen((b) => {
    isMobile = b;
});
class MobileFooter extends React.Component {
  state = {
    isMobile,
  };
  static defaultProps = {
    className: 'footer1',
  };
  componentDidMount() {
    this.map = new mapContainer('map')
  }

  getLiChildren = (data) =>
    data.map((item, i) => {
      const { title, childWrapper, ...itemProps } = item;
      return (
        <Col key={i.toString()} {...itemProps} title={null} content={null}>
          <h2 {...title}>
            {typeof title.children === 'string' &&
              title.children.match(isImg) ? (
              <img src={title.children} width="100%" alt="img" style={{ marginBottom: 40 }} />
            ) : (
              title.children
            )}
          </h2>
          <div {...childWrapper}>
              <div className={`foot-bottom-line bottom-line${i}`}>
              {childWrapper.children.map(getChildrenToRender)}
              </div>
          </div>
        </Col>
      );
    });
    
  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;
    const childrenToRender = this.getLiChildren(dataSource.block.children);
    const iconStyle = {
      fontSize: 22,
      color:"#999",
      margin:"0 6px 0 6px"
    }
    return (
      <div {...props} {...dataSource.wrapper}>
        <Row className='footer-box'>
              {childrenToRender}
            <div id='map' style={{ width: 350, height: 220,margin:"0 auto" }}>
              <div id="popup" className="ol-popup" >
                <div id="popup-content">
                  <div className='title'>MetriDynamic (Chengdu) Technology Co., Ltd</div>
                  {/* <Row>
                    <Col span="6">
                      <Image preview={false} src="http://qiniu.metridynamic.com/image/logo/logo.png" />
                    </Col>
                    <Col xs={24} md={16}>
                      <div>Address：Building 50, Chengdu Landing contemporary art base</div>
                      <div>Email:frank.l@metridynamic.com</div>
                    </Col>
                  </Row> */}
                </div>
              </div>
            </div>
         

        </Row>
        <div style={{ display: "flex",justifyContent:"center",alignItems:"center",paddingTop:12 }}>
            <p>
              <a href="https://www.facebook.com/MetriDynamic" style={{ color: "#000" }}>
                <FacebookOutlined style={iconStyle} />
              </a>

              <a href="https://www.youtube.com/channel/UCPjNc3YV3Pq6oyYCiEd76aQ" style={{ color: "#000" }}>
                <YoutubeOutlined style={iconStyle} />
              </a>

              <a href="https://www.linkedin.com/company/metridynamic" style={{ color: "#000" }}>
                <LinkedinOutlined style={iconStyle} />
              </a>
            </p>
          </div>

          <TweenOne
            animation={{ y: '+=30', opacity: 0, type: 'from' }}
            key="copyright"
            {...dataSource.copyrightWrapper}
          >
            <div {...dataSource.copyrightPage}>
              <div {...dataSource.copyright}>
                {dataSource.copyright.children}
              </div>
            </div>
          </TweenOne>
      </div>
    );
  }
}

export default MobileFooter;
