import React from "react";
import QueueAnim from 'rc-queue-anim';
import './style.less'
import { Tabs, Radio, Space } from 'antd';
const { TabPane } = Tabs;

export default class ObliqueVideoContent extends React.Component {

    render() {
        return (
            <div className="Oblique-video-container">
                <h1>3D city model made from<br></br> DG3M oblique camera</h1>
                <div className={"content"}>
                    <div className="video-container video">
                        <video 
                         x5-playsinline="true"
                         playsinline="true" 
                         webkit-playsinline="true"
                        src="http://qiniu.metridynamic.com/video/new%20video/20220424-142821.mp4" autoPlay loop muted>

                        </video>
                    </div>
                </div>
            </div>
        )

    }
}
