import React from "react";
import './index.less';
import LidarBanner from "./components/banner";
import LidarContent1 from "./components/content1";
import ParametersContent from "./components/parametersContent";
import LidarVideoContent from "./components/videoContent";
import MobileLidarContent1 from "./components/mobilecontent1";
import { enquireScreen } from 'enquire-js';
let isMobile;
enquireScreen((b) => {
    isMobile = b;
});
export default class Lidar extends React.Component {
    state = {
        isMobile
    }
    componentDidMount() {
        // 返回顶部
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div className={"Lidar"}>
                <LidarBanner />
                {
                    this.state.isMobile
                        ?
                        <MobileLidarContent1 />
                        :
                        <LidarContent1 />
                }
                <ParametersContent />
                <LidarVideoContent />
            </div>
        )

    }
}
