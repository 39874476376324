import React from "react";
import QueueAnim from 'rc-queue-anim';
import './index.less'
import {Card, Col, Row} from 'antd';

const {Meta} = Card;

export default class LidarContent1 extends React.Component {
    callback(key) {
        console.log(key);
    }

    render() {
        const cardStyle = {
            width: 320,
            padding: 8,
            margin: "110px auto 0 auto",
            background: "transparent",
            border: "none"
        }
        return (
            <div className='Lidar-content1'>
                <QueueAnim
                    key="QueueAnim"
                    type={['bottom', 'top']}
                    delay={200}
                    className='banner0-text-wrapper'
                >
                    <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                            <Card
                                hoverable
                                cover={<img alt="example"
                                            src="http://qiniu.metridynamic.com/image/new-official-en/lidar/UVX-1LR.png"/>}
                                style={cardStyle}
                            >
                                <Meta title="miniVUX-1LR" description=""/>

                            </Card>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                            <Card
                                hoverable
                                cover={<img alt="example"
                                            src="http://qiniu.metridynamic.com/image%2Fnew-official-en%2Flidar%2F20220427-140950.png"/>}
                                style={cardStyle}
                            >
                                <Meta title="VUX-1LR²²" description=""/>

                            </Card>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                            <Card
                                hoverable
                                cover={<img alt="example"
                                            src="http://qiniu.metridynamic.com/image/new-official-en/lidar/UVX-120.png"/>}
                                style={cardStyle}
                            >
                                <Meta title="VUX-120" description=""/>
                            </Card>
                        </Col>
                    </Row>

                </QueueAnim>
            </div>
        )

    }
}
