import React from "react";
import './style.less';
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';
import { Row, Col } from 'antd'

export default class InsuranceContent0 extends React.Component {
    render() {
        return (
            <div className={'insurance-content0'}>
                <h1>
                    <Texty>
                        " Fly Safer and Longer with Full Cover Insurance "
                    </Texty>
                </h1>
                <Row justify="center">
                    <Col span={"20"}>
                        <div className={'words desc1'}>
                            <div className={'point'}></div>
                            <div className={'title'}>
                                Body damage insurance:
                            </div>
                            <div className={'content'}>
                                During the insurance period,if an operator with a legal license causes accicental loss or damage to the drone curing the operation, the insurer shall comply with settlement of claims as agreed in the insurance contract.
                            </div>
                        </div>
                        <div className={'words-tip'}>
                            <p>Applicable to Mainland China and most countries in the world</p>
                        </div>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col span={"20"}>
                        <div className={'words desc2'}>
                            <div className={'point'}></div>
                            <div className={'title'}>
                                Third-party liability insurance:
                            </div>
                            <div className={'content'}>
                                During this insurance period, if an operator with a legal license causes an accident in the crone and causes a third party to suffer personal injury or property damage during the operation, the insurer shall settle the claim in accordance with the agree-ment of the insurance contract.
                            </div>
                        </div>
                        <div className={'words-tip'}>
                            <p>Only applicable to Mainland China</p>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
