import React from "react";
import './style.less';
import OrthographicBanner from "./components/banner";
import OrthographicContent1 from "./components/content1";
import OrthographicContent2 from "./components/content2";
import OrthographicVideoContent from "./components/videoContent";
export default class OrthographicCamera extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        // 返回顶部
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div className={"orthographic-camera"}>
                <OrthographicBanner />
                <OrthographicContent1 />
                <OrthographicContent2 />
                <OrthographicVideoContent />
            </div>
        )
    }
}
