import React from "react";
import './index.less';
import DatalinkBanner from "./components/banner";
import DatalinkContent1 from "./components/content1";
import DatalinkContent2 from "./components/content2";
import ParametersContent from "./components/parametersContent";
import DatalinkVideoContent from "./components/videoContent";
import Content0 from "./components/content0";
import MobileContent0 from "./components/Mobilecontent";
import { enquireScreen } from 'enquire-js';
let isMobile;
enquireScreen((b) => {
    isMobile = b;
});

export default class Datalink extends React.Component{
    state={
        isMobile
    }
    componentDidMount() {
        // 返回顶部
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div className={"Datalink"}>
                <DatalinkBanner/>
                {
                    this.state.isMobile
                    ?
                    <MobileContent0/>
                    :
                    <Content0/>
                }
                <DatalinkContent1/>
                <DatalinkContent2/>
                <ParametersContent/>
                <DatalinkVideoContent/>
            </div>
        )
    }
}
