import React from "react";
import './index.less';
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';

export default class PayloadVideo extends React.Component{
    constructor(porps) {
        super(porps);
        this.state={
            percentage: 0,
            textShow: true
        }
    }
    componentDidMount() {

    }
    getEnter = (e) => {
        switch (e.index) {
            case 0:
                return {
                    rotate: 90,
                    opacity: 0,
                    y: -60,
                };
            case 10:
            case 1:
                return {
                    y: -60,
                    x: -10,
                    opacity: 0,
                };
            case 9:
            case 2:
                return {
                    y: -60,
                    x: 20,
                    opacity: 0,
                };
            case 3:
                return {
                    y: 60,
                    opacity: 0,
                };
            case 8:
            case 4:
                return {
                    x: 30,
                    opacity: 0,
                };
            case 5:
                return {
                    enter: [
                        {
                            scale: 2,
                            opacity: 0,
                            type: 'set',
                        },
                        { scale: 1.2, opacity: 1, duration: 300 },
                        { scale: 0.9, duration: 200 },
                        { scale: 1.05, duration: 150 },
                        { scale: 1, duration: 100 },
                    ],
                    leave: {
                        opacity: 0, scale: 0,
                    },
                };
            case 6:
                return {
                    scale: 0.8,
                    x: 30,
                    y: -10,
                    opacity: 0,
                };
            case 7:
                return {
                    scale: 0.8,
                    x: 30,
                    y: 10,
                    opacity: 0,
                };
            default:
                return {
                    opacity: 0,
                };
        }
    }

    render() {
        return (
                <div className='payload-sticky-container'>
                    <div className="text-wrapper-video">
                        <Texty enter={this.getEnter} leave={this.getEnter} delay={300}>{this.state.textShow && 'Various Payload Interchangeable'}</Texty>
                    </div>
                    <div className="video-container video">
                        <video
                         x5-playsinline="true"
                         playsinline="true" 
                         webkit-playsinline="true"
                        src="http://qiniu.metridynamic.com/video/new%20video/payloadVideo.mp4" autoPlay loop muted>

                        </video>
                    </div>
                </div>
        )
    }
}
