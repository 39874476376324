import React from "react";
import './index.less'
import GimbalBanner from "./components/banner";
import GimbalContent1 from "./components/content1";
import ParametersContent from "./components/parametersContent";
import GimbalVideoContent from "./components/videoContent";
import MobileGimbalContent1 from "./components/MobileContent1";
import MobileParametersContent from "./components/MobileParametersContent";
import { enquireScreen } from 'enquire-js';
let isMobile;
enquireScreen((b) => {
    isMobile = b;
});
export default class Gimbal extends React.Component{
    state = {
        isMobile
    }
    componentDidMount() {
        // 返回顶部
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div className={"gimbal"}>
                <GimbalBanner/>
                {
                    this.state.isMobile
                    ?
                    <MobileGimbalContent1/>
                    :
                    <GimbalContent1/>
                }
                {
                    this.state.isMobile
                    ?
                    <MobileParametersContent/>
                    :
                    <ParametersContent/>
                }
                <GimbalVideoContent isMobile={this.state.isMobile}/>
            </div>
        )

    }
}
